import React from 'react';

const Modal = (props) => {
    const { body, classBody, classBtnAccept, classBtnCancel, idModal, onClickAccept, showBtnCancel, txtAccept, txtCancel, title } = props;

    return (
      <div className="modal fade" id={idModal} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-header">
              <div className="modal-title" id="exampleModalLabel">{title}</div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              <div className={classBody}>
                {body}
              </div>
            </div>

            <div className="modal-footer">
              {showBtnCancel
                ? <button className={classBtnCancel} data-dismiss="modal">{txtCancel}</button>
                : <></>}
              <button className={classBtnAccept} onClick={() => onClickAccept()}>{txtAccept}</button>
            </div>

          </div>
        </div>
      </div>
    )
}

export {Modal};
