import React from 'react';
import {strings} from '../../../../../common/resources/locales/i18n';
import {Link} from 'react-router-dom';

//Components
import { AsyncImage } from '../../../components/AsyncImage';

//Resources
import {getAsset} from '../../../../../common/resources/assets/assets';

const ModelsItemNoStock = (props) => {
  const { description, image, urlRedirect, configuratorText, alt } = props;

  return (
    <div className="col-md-6 col-lg-3">
      <div className="card card-model no-stock mb-4">
        <div className="card-header position-relative pb-0">
          <AsyncImage
            styleContainer='item'
            source={image}
            style='img-fluid'
            srcPlaceholder={getAsset('icPlaceholderModel')}
            minHeightLoading='184px'
            alt={alt}
          />
        </div>

        <div className="card-body text-center pt-2">
          <h3 className="h4 text-muted">{description}</h3>
          <div className="h6 text-muted">{strings("models.tmpOutStock")}</div>
        </div>

        <div className="card-body card-body-hidden">
          <Link to={{ pathname: urlRedirect }} className="btn btn-outline-secondary btn-block" target="_blank" >
            {configuratorText}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ModelsItemNoStock
