import React, { Component } from 'react';
import {strings} from '../../../common/resources/locales/i18n';

class SelectInput extends Component {

  state = {
    enableOnBlur: false,
  }

  render() {

    const {
      dataChildren, id, description, disabled, onChange, value, error,
      title, showSelect, getItemId, submit, styleContainer, styleTitle, titleSelect,multiple,
      customStyleSelect
    } = this.props

    let hasError = false;
    let elementError = '';

    if (error) {
      error.map((item) => {
        if (item.key === id) {
          hasError = true;
          return elementError = item;
        }
      });
    }

    return (
      <div className={styleContainer}>
        <div className="form-group">

            {title ? <label htmlFor={id} className={styleTitle}>{title}</label> : <></>}

            <select
              multiple={multiple}
              id={id}
              className={`${customStyleSelect ? customStyleSelect : 'form-control custom-select'} ${hasError ? 'error-select' : ''} ${!hasError && submit ? 'success-select' : ''}`}
              value={value}
              onChange={(e) => onChange(e) }
              disabled={disabled}
              title={strings('form.error.selectOption')}
            >
              {showSelect ? <option value={'0'}>{titleSelect ? titleSelect : strings("placeholder.select")}</option> : <option disabled={true} hidden={true}/>}
              {this.renderOptionsItems(dataChildren, getItemId)}
            </select>
            {this.showError(hasError, elementError)}

        </div>
        {description ? <div className="input-info" htmlFor="card-title">{description}</div> : <div/>}
      </div>
    );
  }

  showError(hasError, elementError) {
    if (hasError && elementError) {
      return (
        <div className="error-msg">{elementError.value}</div>
      )
    }
    return (<></>)
  }

  renderOptionsItems (dataChildren, getId) {

    if (dataChildren){
      return dataChildren.map((item) => {
        return(
          <option key={item.id} value={getId ? item.id : item.value}>{item.value}</option>
        )
      })
    }

    return <div/>
  }


  /* PRIVATE METHOD */
  _onBlur(e) {
    const { onBlur } = this.props;
    this.setState({ enableOnBlur: true });
    onBlur(e)
  }
}

export default SelectInput;
