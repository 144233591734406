//Actions
import {getModelsHome} from '../../services/api/API';

//Resources
import Types from './Types';
import {isDev} from '../../utils/Utils';

export const apiGetModels = (props) => async(dispatch) => {
  dispatch(setModelsState({ prop: 'isLoadingModels', value: true }));
  await dispatch(getModelsHome(
    props,
    (tag, response) => {
      if (isDev()) console.log('apiGetModels - Error: ', response);
      dispatch({ type: Types.GET_MODELS_HOME_FAILED });

    },
    (tag, response) => {
      if (isDev()) console.log('apiGetModels - Success: ', response);
      dispatch({
        type: Types.GET_MODELS_HOME_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setModelsState({ prop: 'isLoadingModels', value: false }));
};

export const setModelsState = ({ prop, value }) => ({
  type: Types.SET_MODELS_STATE,
  payload: { prop, value }
});
