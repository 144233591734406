import React, { Component } from 'react';
import PropTypes from 'prop-types';

class CheckboxInput extends Component {

  state = {
    enableOnBlur: false,
  }

  render() {
    const { containLabel, id, error, checked, onChange, submit, notCheck, extraElement } = this.props;

    let hasError = false;
    let hasErrorRequest = false;
    let elementError;

    if (error) {
      error.map((item) => {
        if (item.key === id) {
          hasError = true;
          return elementError = item;
        }
        if (item.key === 'request') {
          hasErrorRequest = true;
        }
      });
    }

    const label = (
      <label className={`${notCheck ? 'form-check-label custom-control-label' 
        : `form-check-label custom-control-label ${hasError ? 'error-checkbox' : ""} ${!hasError && !hasErrorRequest && submit ? "success-checkbox" : ""}`}`} 
        htmlFor={id}
        style={extraElement ? {display: 'inline'} : {}}>
        {containLabel}
      </label>
    )
    return (
      <div className="form-check custom-control custom-checkbox">
        <input 
          className={`form-check-input custom-control-input`}
          type="checkbox" 
          id={id} 
          checked={checked}
          onChange={(e) => onChange(e)}
        />
        {label}
        {extraElement}
        {this.showError(hasError, elementError)}

      </div>
    );
  }

  showError(hasError, elementError) {
    if (hasError && elementError) {
      return (
        <div className="error-msg">{elementError.value}</div>
      )
    }
    return (<div />)
  }

  renderClearInput(disabled, onChange, id) {

    if (disabled) {
      return <div />
    }

    return (
      <i className="clear-input" onClick={() => onChange({ target: { id: id, value: '' } })}>
        <ion-icon name="close-circle-outline" />
      </i>
    )
  }

  /* PRIVATE METHOD */
  _onBlur(e) {
    const { onBlur } = this.props;
    this.setState({ enableOnBlur: true });
    onBlur(e)
  }
}

CheckboxInput.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onKeyDown: PropTypes.string,
  error: PropTypes.array,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  onBlur: PropTypes.func,
};


export default CheckboxInput;
