import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from "react-lazyload";

import Routing from '../../routing/Routing';
import { getAsset } from '../../../../../common/resources/assets/assets';
import { strings } from '../../../../../common/resources/locales/i18n';


//Components
import { AsyncImage } from '../../../components/AsyncImage';
import { addPixelFacebook, getVinPixelFacebook, isPro } from '../../../../../common/utils/Utils';

const VehiclesItems = (props) => {
  const {
    modelGroupDescription, productLineDescription, gearboxDescription, fuelDescription, colorDescription,
    finalPrice, finalPriceFormat, price, priceFormat, images, index, cuotaPriceFormat, id, power, addComparator,
    hasSpecialPrice, showModal, item
  } = props;

  return (
    <div
      key={index}
      className={!hasSpecialPrice ? "card card-product mb-4 shadow-sm border-0" : "card card-product mb-4 shadow-sm border-secondary"}
      style={!hasSpecialPrice ? { } : {borderWidth: 2}}
    >
      <div className="card-body row d-flex align-items-center">

        {<div className="position-absolute top-0 left-0" style={{ zIndex: 999 }}>
          <Link to={Routing.nowhere} onClick={() => addComparator()} className="py-2 text-secondary" data-toggle="tooltip" data-placement="top"
            title={strings('tooltip.addComparer')}>
            <ion-icon name="duplicate-outline" />
          </Link>
        </div>}

        <div className="col-md-4 col-lg-4">
          <div className="position-relative card-img">
            <div>
              {renderImages(images, modelGroupDescription)}
            </div>
          </div>
        </div>

        <div className="col-md-8 col-lg-8 card-info">

          <div className="row d-flex align-items-center mt-4 mt-md-0 mb-4">
            <div className="col-12">
              <h3 className="h4">
                <Link to={`${Routing.vehicleDetail}${id}`} className={!hasSpecialPrice ? "text-primary" : "text-secondary"}>
                  {`${modelGroupDescription} ${productLineDescription}`}
                </Link>
              </h3>

              <ul className="list-inline small text-dark text-uppercase">
                {power ? 
                  <li className="list-inline-item">
                    {strings('vehicleDetails.powerValue', {value: power})}
                  </li> :
                  <></>
                }
                {fuelDescription ? 
                  <li className="list-inline-item">
                    <ion-icon name="flash-outline"/>{' ' + fuelDescription}
                  </li> : 
                  <></>
                }
                {gearboxDescription ? 
                  <li className="list-inline-item">
                    <ion-icon name="cog-outline"/>{' ' + gearboxDescription}
                  </li> : 
                  <></>
                }
                {colorDescription ? 
                  <li className="list-inline-item">
                    <ion-icon name="color-filter-outline"/>{' ' + colorDescription}
                  </li> : 
                  <></>
                }
              </ul>

            </div>
          </div>

          <div className="row d-flex align-items-end mb-0">
            <div className="col-md-7">
              <div className="row mb-3 mb-md-0">
                <div className="col-6">
                  <p className="mb-0 small text-muted">{strings("models.from")}<br />
                    <span className={!hasSpecialPrice ? "h5 text-primary" : "h5 text-secondary"}>
                      {cuotaPriceFormat} <small className="small-lg">{strings("models.from2")}</small></span></p>
                </div>

                <div className="col-md-6">
                  <p className="mb-0 small text-muted">{strings('models.pvp')}<br />
                    <span className="h5 text-dark">{finalPriceFormat}<br />
                      {finalPrice !== price ? <small className="text-muted small ml-1"><s>{priceFormat}</s></small> : <span />}
                    </span>
                  </p>
                </div>

              </div>
            </div>
            <div className="col-md-5 small">
              <Link to={`${Routing.vehicleDetail}${id}`} className={!hasSpecialPrice ? "btn btn-sm btn-block btn-primary text-uppercase" : "btn btn-sm btn-block btn-outline-secondary text-uppercase"}>{strings('button.moreInformation')}</Link>
              <Link 
                to={Routing.nowhere} 
                onClick={() => {
                  showModal();
                  if (isPro() && addPixelFacebook) addPixelFacebook(false, true, getVinPixelFacebook(item));
                }} 
                className={!hasSpecialPrice ? "btn btn-sm btn-block btn-gold text-uppercase" : "btn btn-sm btn-block btn-secondary text-uppercase"} 
                data-toggle="modal" 
                data-target="#modalReservation">
                {strings('button.imInterested')}
              </Link>
            </div>
          </div>

        </div>

      </div>
    </div>
  )
}

const renderImages = (images, modelGroupDescription) => {
  if (images && images.length !== 0) {
    return (
      <LazyLoad>
        <div className='item'>
          <AsyncImage
            source={images[0]}
            style='img-fluid'
            srcPlaceholder={getAsset('icPlaceholderModel')}
            alt={modelGroupDescription}
          />
        </div>
      </LazyLoad>
    )
  }
}

export default VehiclesItems
