import React, { Component } from 'react'
import { connect } from 'react-redux'

class ModalBanner extends Component {
  render() {
    const { popupCmsContent } = this.props;
    
    return (
      <div className="modal fade" id="modalBanner" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-centered" role="form">

          {/* <!-- Content --> */}
          <div className="modal-content">
            <div className="modal-body p-0">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <ion-icon name="close-outline"></ion-icon>
              </button>
              <span dangerouslySetInnerHTML={{__html: popupCmsContent}}/>
            </div>
          </div>
          {/* <!-- /Content --> */}

        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ AppReducer }) => {
  const { popupCmsContent, } = AppReducer;

  return {
    popupCmsContent,
  }
};

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(ModalBanner)
