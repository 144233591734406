/* eslint max-len: 0 */ // --> OFF

export default {
  /** ****** */
  /** GLOBAL */
  /** ****** */

  global: {
    appName: 'Stock Finder',
    appNameCanonical: 'Stock Finder',
    appNameGroup: 'Domingo Alonso',
  },

  button: {
    add: 'Añadir',
    accept: 'Aceptar',
    account: 'Mi Cuenta',
    back: 'Atrás',
    backToInit: 'Volver al Inicio',
    buy: 'Comprar',
    cancel: 'Cancelar',
    clear: 'Limpiar',
    create: 'Crear',
    close: 'Cerrar',
    connect: 'Conectar',
    confirm: 'Confirmar',
    configuration: 'Configuración',
    contact: 'Contacto',
    continue: 'Continuar',
    delete: 'Eliminar',
    edit: 'Editar',
    eraseEverything: 'Borrar todo',
    exit: 'Salir',
    forgotPassword: '¿Olvidaste la contraseña?',
    goToStore: 'Ir a {{store}}',
    help: 'Ayuda',
    info: 'Info',
    imInterested: 'Me interesa',
    loading: 'Cargando...',
    login: 'Acceder',
    logout: 'Cerrar sesión',
    ok: 'ok',
    menu: 'menu',
    modify: 'Modificar',
    moreInformation: 'Más información',
    no: 'No',
    pay: 'Pagar',
    payment: 'Pago',
    register: 'Registro',
    rememberPassword: 'Recordar clave',
    restartApp: 'Reiniciar app',
    restore: 'Restablecer',
    retry: 'Reintentar',
    returnsExchanges: 'Cambios y Devoluciones',
    save: 'Guardar',
    search: 'Buscar',
    send: 'Enviar',
    sendRequest : 'Enviar petición',
    sendRequest2 : 'Enviar solicitud',
    shop: 'Tienda',
    update: 'Actualizar',
    yes: 'Sí',
  },

  error: {
    appCrash: '¡Oops!, algo no ha ido bien.\nPedimos disculpas por esto.\nPulsa el botón para reiniciar la aplicación.',
    completeThisField: 'Por favor, completa este campo.',
    linkingError: 'Se ha producido un error intentando abrir el enlace',
    networkError: '¡Oops! Parece que hay algún problema con la conexión a Internet. Revise la conexión e inténtelo nuevamente',
    networkErrorShort: 'Estás desconectado, por favor verifica tu conexión a internet',
    server400: '¡Oops! Parece que algo no ha ido bien. Por favor, inténtelo de nuevo o más tarde',
    server401: '¡Oops! Parece que no hemos podido validar tus credenciales. Por favor, cierra sesión y vuelve a introducir tus credenciales',
    server500: '¡Oops! Parece que algo no ha ido bien. Por favor, inténtelo de nuevo más tarde',
    sessionExpireTitle: 'Sesión expirada',
    sessionExpireMessage: 'Tu sesión ha expirado. Por favor, inicia sesión otra vez para continuar usando la aplicación',
  },

  tooltip: {
    addComparer: 'Añadir al comparador',
    sendToEmail: 'Enviar por email',
    removeComparer: 'Eliminar del comparador',
  },

  /** *** */
  /** APP */
  /** *** */

  form: {
    error: {
      invalidAddress: 'Dirección no válido',
      invalidCardCVV: 'CVV no válido',
      invalidCardExpireMonth: 'Mes no válido',
      invalidCardExpireYear: 'Año no válido',
      invalidCardHolder: 'Titular no válido',
      invalidCardNumber: 'Número de tarjeta no válido',
      invalidCity: 'Ciudad no válido',
      invalidContactMethod: 'Método de contacto no válido',
      invalidDay: 'Día no válido',
      invalidEmail: 'Correo electrónico no válido',
      invalidGender: 'Selecciona una opción',
      invalidLastName1: 'Apellido 1 no válido',
      invalidLastName2: 'Apellido 2 no válido',
      invalidMessage: 'Mensaje no válido. Mínimo 100 caracteres',
      invalidMonth: 'Mes no válido',
      invalidName: 'Nombre no válido',
      invalidPasswordCoincidence: 'Las contraseñas no coinciden',
      invalidPasswordLength: 'La contraseña debe tener al menos 6 caracteres',
      invalidPhoneNumber: 'Número de teléfono no válido',
      invalidPostalCode: 'Código postal no válido',
      invalidProvinceSelected: 'Seleccione una provincia',
      invalidSize: 'Talla inválida',
      invalidSubject: 'Asunto no válido',
      invalidTaxNumber: 'NIF / NIE / ID no válido',
      invalidValue: 'Este campo no puede estar vacío',
      invalidYear: 'Año no válido',
      selectOption: 'Seleccionar una opción',
      selectSchool: 'Seleccione un colegio',
    },
  },

  placeholder: {
    concessionaire: 'Concesionario',
    email: 'Email',
    island: 'Isla',
    name: 'Nombre',
    phone: 'Teléfono',
    model: 'Modelo',
    select: 'Seleccionar...',
    selectConcessionaire: 'Selecciona tu concesionario',
    selectConcessionaire2: 'Seleccionar concesionario',
    selectIsland: 'Selecciona tu isla',
    selectModel: 'Selecciona tu modelo',
    surnames: 'Apellidos',
    searchBox: 'Buscar por nombre o referencia...',
  },

  title: {
    help: 'ayuda',
    home: 'Home',
    vehicles: 'Vehículos',
    sections: 'secciones',
  },

  /** ******* */
  /** MODULES */
  /** ******* */

  comparator: {
    color: 'Color',
    emptyList: 'Todavía no has añadido vehículos para comparar',
    quickFiltering: 'Filtrado rápido:',
    price: 'Precio',
  },

  contact: {
    askInformationServices: 'Pide información sobre nuestros servicios',
    calculateCustomFee: 'Calcula tu cuota personalizada',
    contactAdvisor: 'Contacta con un asesor',
    customCommercialCommunications: 'Queremos adaptar nuestras Comunicaciones Comerciales personalizadas según tus gustos, preferencias y análisis de tu perfil',
    financialCalculator: 'Datos de la calculadora financiera',
    notSeeQuota: 'No quiero ver cuota',
    readAgree: 'He leído y acepto la ',
    receiveCommercialCommunications: '¿Te gustaría recibir comunicaciones Comerciales de Domingo Alonso Group?',
    share: 'Cuota',
    privacyPolicy: 'Política de privacidad',
    legalText1: {
      moreInfo: '(+Info)',
      paragraph1: 'Información básica sobre Protección de Datos:',
      paragraph2: 'Responsable: HYUNDAI CANARIAS S.L.U',
      paragraph3: 'Finalidad: Gestión de consultas y solicitudes de información.',
      paragraph4: 'Derechos: puede ejercitar su derecho de acceso, rectificación, supresión y otros, tal como aparece en la información ampliada que puede conocer visitando nuestra',
      privacyPolicy: 'política de privacidad',
    },
    legalText2: {
      moreInfo: '(+Info)',
      paragraph2: 'Responsable: DOMINGO ALONSO GROUP S.L.',
      paragraph3: 'Finalidad: Envío de información publicitaria y elaboración de perfiles comerciales.',
      paragraph4: 'Derechos: puede ejercitar su derecho de acceso, rectificación, supresión y otros, tal como aparece en la información ampliada que puede conocer en nuestra web',
      paragraph5: '*Este apartado no es indispensable para prestarle los servicios solicitados',
      webDomingoAlonso: 'www.domingoalonsogroup.com',
    },
  },

  navBar: {
    backToSite: ' Volver al sitio',
    contactAdvisor: ' Contactar con asesor',
    comparator: ' Comparador ({{value}})',
    promotions: ' Promociones',
    searchFilter: ' Buscar',
    viewStock: ' Todo el stock',
  },

  maintenance:{
    siteUnderMaintenance: "Sitio en mantenimiento",
    backSoon: "Volveremos enseguida"
  },

  msgConfirmation: {
    backHomepage: 'Volver al inicio',
    msgSent: 'Tu mensaje ha sido enviado correctamente.',
    msgSent2: 'Un agente especializado te contactará en 24 horas.',
    thank: 'Muchas gracias',
  },

  modals: {
    comparatorFull: {
      body: 'El comparador tiene un límite de {{value}} vehículos, puede eliminar algunos si desea añadir éste a la lista.',
      txtAccept: 'Ir a comparador',
      txtCancel: 'OK',
      title: 'Comparador lleno',
    }
  },

  modalOptions: {
    askDate: 'Pedir cita concesionario',
    comparator: 'Comparador',
    contactAdvisor: 'Contactar con un asesor',
    selectOption: 'Seleccionar opción',
  },

  models: {
    backToTheList: 'Volver al listado de',
    choosePrefences: 'Elige tus preferencias',
    emptyList: 'Actualmente no hay modelos disponibles',
    contactAdvisor: 'Contactar con asesor',
    customizeFinancing: 'Personaliza tu financiación',
    customizeHyundai: 'Personaliza tu Hyundai',
    customizeQuotas: 'Personalizar cuotas',
    customizeYourModel: "Personaliza tu modelo",
    from: "Desde ",
    from2: " / mes*",
    monthlyFee: 'Cuota mensual',
    notFoundLooking: '¿No encuentras lo que buscas?',
    hyundaiAreYouLooking: "¿Qué Hyundai estás buscando?",
    pvp: "PVP",
    pvp2: "{{price}}€",
    seeInStock: "Consulta nuestro stock",
    spotPrice: 'Precio de venta **',
    stockHyundai: "Stock Hyundai Canarias",
    subjectToFinal: 'Sujeto a oferta final del concesionario',
    tmpOutStock: "Próximamente en stock",
  },

  page404:{
    pageNotFound: "Página no encontrada",
    pageNotFoundDescription: "Lo sentimos, la página que buscas no existe."
  },

  toasts: {
    vehicle: {
      addVehicle: 'Vehículo añadido',
      nameVehicleAdd: 'El vehículo {{vehicle}} ({{price}}) se ha añadido al comparador correctamente',
      notAddVehicle: 'El vehículo {{vehicle}} ({{price}}) ya está en el comparador ',
      notAddVehicleTitle: 'Vehículo no añadido',
    },
    alert: {
      weSorry: 'Lo sentimos',
    }
  },

  vehicles: {
    cuotePrice: 'Cuota máxima',
    contactAdvisor: 'Contactar con un asesor',
    emptyList: 'Actualmente este modelo no está disponible',
    emptyListFilter: 'Actualmente este modelo no está disponible por los filtros indicados.',
    highestPrice: 'Precio más alto',
    lowestPrice: 'Precio más bajo',
    newInStock: 'nuevos en stock',
    news: 'Novedades',
    maximumPrice: 'Precio máximo',
    sortBy: ' Ordenar por ',
    soonBackInStock: 'Muy pronto volverá a estar en stock, puedes contactar con nosotros y solicitar más información.',
    offers: 'Ofertas',
    offersPromotions: 'Ofertas y Promociones',
    termsAndConditions: '*Sujeto a autorización de la entidad colaboradora. Consulte condiciones en concesionario.',
  },

  vehicleDetails: {
    additionalFeatures: 'Extras',
    campaigns: 'Campañas',
    characteristics: 'CARACTERÍSTICAS',
    co2: '{{value}} g/km',
    consumption: 'Consumo WLTP',
    consumptionValue: '{{value}} l / 100 km',
    comfort: 'Confort',
    entry: 'Entrada',
    equipment: 'EQUIPAMIENTO',
    environmentalBadge: 'Distintivo ambiental',
    emptyListFilter: 'El vehículo no se encuentra disponible',
    emissions: 'CO2 WLTP',
    exterior: 'Exterior',
    financedCapital: 'Capital financiado',
    financing: 'FINANCIACIÓN',
    financingDetails: 'Detalles de financiación (estimado)',
    finalFee: 'Cuota final',
    gearbox: 'Cambio {{gearbox}}',
    hyundai: 'Hyundai ',
    initialEntry: 'Entrada inicial',
    interior: 'Interior',
    monthlyFee: 'Cuota mensual',
    openingCommission: 'Comisión apertura',
    optionalEquipment: 'Equipamiento opcional',
    knowMore: '¿Quieres saber más? Conoce todas las características al detalle y convéncete tú mismo.',
    resultMonthFinancing: 'Meses (incluye última cuota)',
    security: 'Seguridad',
    selectedModel: 'Modelo seleccionado',
    shift: 'Cambio',
    simulate: 'Simular',
    standardEquipment: 'Descubre su equipamiento de serie',
    surpriseAllBenefits: 'Sorpréndete con todas sus prestaciones',
    totalInstallmentPrice: 'Precio total a plazos',
    priceFinal: 'Precio final',
    personalInformation: 'Datos personales',
    priceDetail: 'Precio detallado',
    postponement: 'Aplazamiento ({{maxValue}} máx)',
    power: 'Potencia',
    rgpd: 'RGPD',
    powerValue: '{{value}} CV',
    tireLabels: 'Etiquetas Neumáticos ',
    wantThis: '¡Quiero este {{vehicle}}!',
    wantToKnowMore: '¿Quieres saber más? Conoce todas las características al detalle y convéncete tú mismo.',
    xMonths: '{{months}} meses',
  },

};
