import { combineReducers } from 'redux';

//Reducer
import AppReducer from '../modules/app/AppReducer';
import ToastsReducer from '../modules/toasts/ToastsReducer';
import ContactReducer from '../modules/contact/ContactReducer';
import ComparatorReducer from '../modules/comparator/ComparatorReducer';
import ModelsReducer from '../modules/models/ModelsReducer';
import VehiclesReducer from '../modules/vehicles/VehiclesReducer';


const reducer = combineReducers({
  AppReducer,
  ContactReducer,
  ComparatorReducer,
  ModelsReducer,
  VehiclesReducer,
  ToastsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    this.state = undefined;
  }
  return reducer(state, action);
};

export default rootReducer;
