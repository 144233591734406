//Actions
import {getAppConfig, getDealers} from '../../services/api/API';

//Resources
import Types from './Types';
import {isDev} from '../../utils/Utils';

export const apiGetAppConfig = (props) => async(dispatch) => {
  dispatch(setLoadingState(true));
  await dispatch(getAppConfig(
    props,
    (tag, response) => {
      if (isDev()) console.log('apiGetGlobalConfig - Error: ', response);
      dispatch({ type: Types.GET_CONFIG_FAILED });

    },
    (tag, response) => {
      if (isDev()) console.log('apiGetGlobalConfig - Success: ', response);
      dispatch({
        type: Types.GET_CONFIG_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setLoadingState(false));
};

export const apiGetDealers = (props) => async(dispatch) => {
  dispatch(setLoadingState(true));
  await dispatch(getDealers(
    props,
    (tag, response) => {
      if (isDev()) console.log('apiGetDealers - Error: ', response);
      dispatch({ type: Types.GET_DEALERS_FAILED });

    },
    (tag, response) => {
      if (isDev()) console.log('apiGetDealers - Success: ', response);
      dispatch({
        type: Types.GET_DEALERS_SUCCESS,
        payload: response
      });
    }
  ));
  dispatch(setLoadingState(false));
};

export const setAppState = ({prop, value}) => async(dispatch) => {
  dispatch({
    type: Types.SET_APP_CONFIG_STATE,
    payload: {prop, value}
  })
}


export const setLoadingState = (value) => async(dispatch) => {
  dispatch({
    type: Types.SET_LOADING_APP_CONFIG,
    payload: value
  })
};
