import React from 'react';

const Loading = () => {
  return(
    <div className="text-center">
      <div className="spinner-border text-primary" role="status"/>
    </div>
  )
}

export {Loading}
