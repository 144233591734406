export default class ModelsManager {
  static getId = (data) => {
    return data && data.id ? data.id : '';
  };
};

export const ToastConstructor = ({id, item, delay, title, body, icon}) => {
  return {
    body: body,
    delay: delay ? delay : 0,
    id: id ? id : uniqueID(),
    icon: icon,
    item: item,
    title: title,
  };
};


function uniqueID() {
  return Math.floor(Math.random() * Date.now())
}
