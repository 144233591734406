import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Components
import { ImageSvg } from './ImageSvg';
import { Loading } from './Loading';
import Zoom from 'react-img-zoom'

class AsyncImage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }

  render() {
    const { 
      addClassDivLoading, alt, source, 
      style, styleContainer, isLoading, 
      minHeightLoading, minWidthLoading, styleImg,
      zoom, zoomHeight, zoomWidth,
    } = this.props;
    const { loading } = this.state;

    if (!source){
      return(
        this.renderPlaceholder()
      )
    }

    if (zoom) {
      return (

        <div className={styleContainer}>
          { isLoading
            ? <div
                className={`d-flex justify-content-center align-items-center  ${addClassDivLoading ? addClassDivLoading : ''}`}
                style={{minHeight: minHeightLoading, minWidth: minWidthLoading}}
              >
                <Loading />
              </div>
            : <></>
          }
  
          {!isLoading
            ?
            <div className={`cursor-zoom-out ${style}`}>
              <Zoom
            img={source}
            zoomScale={2}
            height={zoomHeight}
            width={zoomWidth}
          />
            </div>
            
            : <></>
          }
        </div>
  
      )
    }

    return (

      <div className={styleContainer}>
        {loading || isLoading
          ? <div
              className={`d-flex justify-content-center align-items-center  ${addClassDivLoading ? addClassDivLoading : ''}`}
              style={{minHeight: minHeightLoading, minWidth: minWidthLoading}}
            >
              <Loading />
            </div>
          : <></>
        }

        {!isLoading
          ?
             <img
              src={source}
              alt={alt}
              className={`${style} ${loading ? 'd-none' : ''}`}
              onLoadCapture={() => this._onLoadEnd()}
              style={styleImg}
            />
          : <></>
        }
      </div>

    )
  }

  renderPlaceholder() {
    const { placeholderColor, placeholderImage, srcPlaceholder, style, stylesImagePlaceholder } = this.props;

    if (srcPlaceholder) {
      return(
        <ImageSvg src={srcPlaceholder} />
      )
    }

    return (
      <img
        className={`${style} ${stylesImagePlaceholder ? stylesImagePlaceholder : ''}`}
        style={{backgroundColor: placeholderColor}}
        src={placeholderImage}
      />
    );
  }

  /** PRIVATE METHODS **/
  _onLoadEnd() {
    this.setState(() => ({ loading: false }));
  }
}

AsyncImage.defaultProps = {
  placeholderColor: 'lightgrey',
};

AsyncImage.propTypes = {
  source:                 PropTypes.string,
  placeholderColor:       PropTypes.string,
  placeholderImage:       PropTypes.number,
  style:                  PropTypes.string,
  stylesImagePlaceholder: PropTypes.string,
};

export { AsyncImage };
