import React, { Component } from 'react';
import { Link } from 'react-router-dom';

//Resources
import Routing from '../routing/Routing';
import { strings } from '../../../../common/resources/locales/i18n';
import { toggleModal } from '../../resources/styles/js/custom'

export default class ModalOptions extends Component {

  render() {

    return (
      <div className="modal fade" id="modalOptions" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="h5 modal-title" id="exampleModalLabel">
                {strings('modalOptions.selectOption')}
              </div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Link to={Routing.nowhere} onClick={() => this._onClickRedirect(Routing.contact)} className="btn btn-outline-primary btn-block btn-lg mb-2">
                {strings('modalOptions.contactAdvisor')}
              </Link>
              {/*<Link to={Routing.nowhere} role="button" className="btn btn-outline-primary btn-block btn-lg mb-2" data-dismiss="modal" data-toggle="modal" data-target="#modalReservation">
                {strings('modalOptions.askDate')}
              </Link>*/}
              <Link to={Routing.nowhere} onClick={() => this._onClickRedirect(Routing.comparator)} className="btn btn-outline-primary btn-block btn-lg">
                {strings('modalOptions.comparator')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /** PRIVATE METHODS **/
  _onClickRedirect(redirect) {
    const { history } = this.props;
    toggleModal('modalOptions');
    history.replace(redirect);
  }

  _onClickReservation() {

  }

}

