import React, { Component } from 'react';
import { connect } from 'react-redux';

//Actions
import {
  clearDataVehicles,
  filterVehicles,
  setVehiclesState,
} from '../../../../common/modules/vehicles/VehiclesActions';

//Components
import TextInput from '../../components/TextInput';

//Resources
import {startSelectPicker, toggleModal} from '../../resources/styles/js/custom';
import Routing from '../routing/Routing';
import { strings } from '../../../../common/resources/locales/i18n';
import VehiclesFilter from '../vehicles/components/VehiclesFilter';

class ModalFilter extends Component {

  render() {
    const { searchBox } = this.props;

    return (
      <div className="modal fade" id="modalFilter" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">

            <div className="modal-header d-flex align-items-center bg-light">
              <div className="h5 modal-title m-0">{strings('navBar.searchFilter')}</div>
              <button type="button" className="close p-3" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={(e) => this._onSubmit(e)}>

              <div className="modal-body">
                <div className="input-group">
                  <TextInput
                    id='searchBox'
                    type='text'
                    placeholder={strings('placeholder.searchBox')}
                    onChange={(e) => this._handleChangeValue(e.target.id, e.target.value)}
                    value={searchBox}
                    onlyInput={true}
                  />
                  <div className="input-group-append">
                    <button className="btn btn-light border" type="submit" id="button-search">
                      <ion-icon name="search-outline" />
                    </button>
                  </div>
                </div>
              </div>


              {this._renderVehicleFilter()}

              <div className="modal-footer">
                <button type="button" className="btn btn-outline-secondary" onClick={() => this._deleteData()}>
                  {strings('button.eraseEverything')}
                </button>
                <button type="submit" className="btn btn-primary">{strings('button.accept')}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }

  _renderVehicleFilter() {
    const {
      availableFilters, dataVehicles, isLoadingVehicles, maxPrice, minPrice,
      colors, fuels, transmissions, price, specialPrice, maxCuota, minCuota, cuota
    } = this.props;

    if ((dataVehicles && dataVehicles.length === 0) && (availableFilters && availableFilters.length === 0) || isLoadingVehicles) {
      return <div />
    }

    startSelectPicker();

    return (
      <div className={'mx-3 d-lg-none'}>
      <VehiclesFilter
        availableFilters={availableFilters}
        onChange={(prop, value) => this._handleValueChangeAndFilter(prop, value)}
        maxPrice={maxPrice}
        minPrice={minPrice}
        colors={colors}
        fuels={fuels}
        transmissions={transmissions}
        price={price}
        specialPrice={specialPrice}
        maxCuota={maxCuota}
        minCuota={minCuota}
        cuota={cuota}
      />
      </div>
    )
  }

  /** PRIVATE METHODS **/
  async _clearDataVehicles() {
    await this.props.clearDataVehicles();
  }

  _deleteData() {
    this._handleChangeValue('searchBox', '');
  }

  _handleChangeValue(prop, value) {
    this.props.setVehiclesState({ prop, value });
  }

  _handleValueChangeAndFilter = (prop, value) => {
    this._handleChangeValue(prop, value);

  };

  _onSubmit(e) {
    e.preventDefault();
    if (toggleModal) toggleModal('searchMobile');
    const { history, searchBox } = this.props;

    if (searchBox === ''){
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      return this.props.filterVehicles()
    }

    history.replace(`${Routing.vehicle}search=${searchBox}`);
    this._deleteData();
    this._clearDataVehicles()
  }

}

const mapStateToProps = ({ VehiclesReducer }) => {
  const { availableFilters, dataVehicles, dataVehicleDetail, dataVehiclesOriginal, isLoadingVehicles, vehiclesCount, maxPrice, minPrice,
    colors, fuels, transmissions, price, specialPrice, currentModelDescription, maxCuota, minCuota, cuota, searchBox } = VehiclesReducer;

  return {
    availableFilters, dataVehicles, dataVehicleDetail, dataVehiclesOriginal, isLoadingVehicles, vehiclesCount, maxPrice, minPrice,
    colors, fuels, transmissions, price, specialPrice, currentModelDescription, maxCuota, minCuota, cuota, searchBox
  };
};

const mapStateToPropsAction = {
  clearDataVehicles,
  setVehiclesState,
  filterVehicles,
};

export default connect(mapStateToProps, mapStateToPropsAction)(ModalFilter);
