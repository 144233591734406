import React, { Component } from 'react'

export default class ModalCalc extends Component {

  render() {
    return (
      <div className="modal fade" id="modalCalc" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div className="h5 modal-title">Calculadora financiera</div>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <ion-icon name="close-outline"></ion-icon>
              </button>
            </div>

            <form>
              <div className="modal-body p-lg-4">
                <div className="form-group">
                  <label htmlFor="customRange1">Entrada</label>
                  <input type="range" className="custom-range" id="customRange1" />
                </div>
                <div className="form-group my-4">
                  <label htmlFor="customRange2">Cuota</label>
                  <input type="range" className="custom-range" id="customRange2" />
                </div>
                <div className="form-group">
                  <label htmlFor="customRange3">Aplazamiento</label>
                  <input type="range" className="custom-range" id="customRange3" />
                </div>
                <div className="result-calc">
                  <h6>Resultado detallado</h6>
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi excepturi, odio optio cupiditate dolore
                    voluptate omnis! Ducimus eos excepturi, iure ea reprehenderit commodi ullam ratione fugit! Voluptates
                    saepe nemo asperiores!</p>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-lg px-5" data-dismiss="modal">Aceptar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
  
}
