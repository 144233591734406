import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TextInput extends Component {

  state = {
    enableOnBlur: false,
  }

  render() {
    const { id, isFormGroup, disabled, name, type, placeholder, onChange, onlyInput, value, error, readOnly, textLabel, submit, styleContainer } = this.props; //missing variable onKeyDown

    //const { enableOnBlur } = this.state

    let hasError = false;
    let hasErrorRequest = false;
    let elementError;

    if (error) {
      error.map((item) => {
        if (item.key === id) {
          hasError = true;
          return elementError = item;
        }
        if (item.key === 'request') {
          hasErrorRequest = true;
        }
      });
    }

    if (onlyInput) {
      return (
        <>
          <input
            id={id}
            name={name}
            className={"form-control " + (hasError ? 'error-input' : "") + (!hasError && !hasErrorRequest && submit ? "success-input" : "")}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e)}
            readOnly={readOnly}
            disabled={disabled}
          />
          {this.showError(hasError, elementError)}
        </>
      )
    }

    return (
      <div className={styleContainer}>
        <div className={isFormGroup ? 'form-group' : ''}>

          {textLabel
            ? <label htmlFor={id}>{textLabel}</label>
            : <></>
          }

          <input
            id={id}
            name={name}
            className={"form-control " + (hasError ? 'error-input' : "") + (!hasError && !hasErrorRequest && submit ? "success-input" : "")}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={(e) => onChange(e)}
            //onBlur={(e) => this._onBlur(e)}
            //onKeyDown={(e) => onKeyDown(e, 'username')}
            readOnly={readOnly}
            disabled={disabled}
          />

          {this.showError(hasError, elementError)}

        </div>
      </div>
    );
  }

  showError(hasError, elementError) {
    if (hasError && elementError) {
      return (
        <div className="error-msg">{elementError.value}</div>
      )
    }
    return (<></>)
  }

  renderClearInput(disabled, onChange, id) {

    if (disabled) {
      return <div />
    }

    return (
      <i className="clear-input" onClick={() => onChange({ target: { id: id, value: '' } })}>
        <ion-icon name="close-circle-outline" />
      </i>
    )
  }

  /* PRIVATE METHOD */
  _onBlur(e) {
    const { onBlur } = this.props;
    this.setState({ enableOnBlur: true });
    onBlur(e)
  }
}

TextInput.propTypes = {
  id: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onKeyDown: PropTypes.string,
  error: PropTypes.array,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  onBlur: PropTypes.func,
};


export default TextInput;
