

export default class VehiclesManager {
  static getCampaigns = (data) => {
    return data && data.campaigns ?
      data.campaigns : '';
  };

  static getOptionalEquipmentList = (data) => {
    return data && data.optional_equipment_list ? data.optional_equipment_list : [];
  }

  static getCo2 = (data) => {
    return data && data.co2 ?
      data.co2 : '';
  };

  static getConsumptionFormatted = (data) => {
    return data && data.consumption_formatted ?
      data.consumption_formatted : '';
  };

  static getColorDescription = (data) => {
    return data && data.color_description ?
      data.color_description : '';
  };

  static getColorId = (data) => {
    return data && data.color_id ?
      data.color_id : '';
  };

  static getColorGroupId = (data) => {
    return data && data.color_group_id ?
      data.color_group_id : '';
  };

  static getConsumption = (data) => {
    return data && data.consumption ?
      data.consumption : '';
  };

  static getCuotaPrice = (data) => {
    return data && data.cuota_price ?
      data.cuota_price : '';
  };

  static getCuotaPriceFormat = (data) => {
    return data && data.cuota_price_format ?
      data.cuota_price_format : '';
  };

  static getEnvironIconUrl = (data) => {
    return data && data.environ_icon_url ?
      data.environ_icon_url : '';
  };

  static getFamilyDescription = (data) => {
    return data && data.family_description ?
      data.family_description : '';
  };

  static getFamilyId = (data) => {
    return data && data.family_id ?
      data.family_id : '';
  };

  static getFinalPrice = (data) => {
    return data && data.final_price ?
      data.final_price : '';
  };

  static getFinalPriceFormat = (data) => {
    return data && data.final_price_format ?
      data.final_price_format : '';
  };

  static getFeaturedEquipment = (data) => {
    return data && data.featured_equipment ?
      data.featured_equipment : '';
  };

  static getFeaturedEquipmentList = (data) => {
    return data && data.featured_equipment_list ?
      data.featured_equipment_list : '';
  };

  static getFuelDescription = (data) => {
    return data && data.fuel_description ?
      data.fuel_description : '';
  };

  static getFuelId = (data) => {
    return data && data.fuel_id ?
      data.fuel_id : '';
  };

  static getGearboxDescription = (data) => {
    return data && data.gearbox_description ?
      data.gearbox_description : '';
  };

  static getHasSpecialPrice = (data) => {
    return data && data.has_special_price ?
      data.has_special_price : false;
  };

  static getInteriorDescription = (data) => {
    return data && data.interior_description ?
      data.interior_description : '';
  };

  static getImagesList = (data) => {
    return data && data.images_list ?
      data.images_list : '';
  };

  static getImageMain = (data) => {
    return data && data.main_image ?
      data.main_image : '';
  };

  static getModelGroupDescription = (data) => {
    return data && data.model_group_description ?
      data.model_group_description : '';
  };

  static getModelUrlKey = (data) => {
    return data && data.model_url_key ?
      data.model_url_key : '';
  };

  static getModelGroupId = (data) => {
    return data && data.model_group_id ?
      data.model_group_id : '';
  };

  static getModelYear = (data) => {
    return data && data.model_year ?
      data.model_year : '';
  };

  static getName = (data) => {
    return data && data.name ?
      data.name : '';
  };

  static getPower = (data) => {
    return data && data.power ?
      data.power : '';
  };

  static getPrice = (data) => {
    return data && data.price ?
      data.price : '';
  };

  static getPriceFormat = (data) => {
    return data && data.price_format ?
      data.price_format : '';
  };

  static getProductId = (data) => {
    return data && data.product_id ?
      data.product_id : '';
  };

  static getProductLineDescription = (data) => {
    return data && data.product_line_description ?
      data.product_line_description : '';
  };

  static getProductLineId = (data) => {
    return data && data.product_line_id ?
      data.product_line_id : '';
  };

  static getVin = (data) => {
    return data && data.vin ?
      data.vin : '';
  };

  static getTyresPdfUrl = (data) => {
    return data && data.tyres_pdf_url ?
      data.tyres_pdf_url : '';
  };

  static pushColor = (colors, colorPush) => {
    let indexDelete = null;
    for (let i = 0; i < colors.length; i++) {
      if (colors[i] === colorPush && indexDelete === null) {
        indexDelete = i;
      }
    }

    if (indexDelete || indexDelete === 0) {
      colors.splice(indexDelete, 1);
    } else {
      colors.push(colorPush);
    }

    return colors;
  }

  static getFinancingQuotaPrice = (resultFinancing) => {
    let result = {quota: '', price: ''};

    if (resultFinancing?.length > 0) {
      for (let i = 0; i < resultFinancing.length; i++) {
        if (resultFinancing[i]?.value_code === "quota") {
          result.quota = resultFinancing[i]?.value_label;
        } else if (resultFinancing[i]?.value_code === "total_amount") {
          result.price = resultFinancing[i]?.value_label;
        }
      }
    }
    
    return result;
  }

}
