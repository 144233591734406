import React, {Component} from "react";
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import {connect} from 'react-redux';
import Routing from './Routing';


// Resources
import PrivateRoute from './PrivateRouter';
import ScrollToTop from './ScrollToTop';
import Navbar from '../navbar/Navbar';

// Views
import ContactContainer from "../contact/ContactContainer";
import ComparatorContainer from '../comparator/ComparatorContainer';
import MessageConfirmation from "../contact/MessageConfirmation";
import ModelsContainer from '../models/ModelsContainer';
import FooterContainer from '../footer/FooterContainer';
import Toast from "../../module/toast/Toast";
import VehiclesContainer from '../vehicles/VehiclesContainer';
import VehiclesDetailsContainer from '../vehicles/VehiclesDetailsContainer';
import Page404Container from './Page404Container';
import MaintenancePage from './MaintenancePage';
import ModalBanner from "../models/ModalBanner";

class RouterComponent extends Component {
  render() {
    const { maintenanceModeEnabled } = this.props;
 
    if (maintenanceModeEnabled){
      return <MaintenancePage/>
    }

    return (
      <BrowserRouter>
        <ScrollToTop>
          <Route component={Navbar}/>
          <Switch>

            {/** HOME **/}
            <PrivateRoute exact path={Routing.home} component={ModelsContainer} accessToken={true} />

            {/** CONTACT **/}
            <PrivateRoute exact path={Routing.contact} component={ContactContainer} accessToken={true} />
            {/** COMPARATOR **/}
            <PrivateRoute exact path={Routing.comparator} component={ComparatorContainer} accessToken={true} />

            {/** VEHICLE **/}
            <PrivateRoute exact path={Routing.vehicleSearch} component={VehiclesContainer} accessToken={true} />
            <PrivateRoute exact path={`${Routing.vehicle}${Routing.id}`} component={VehiclesContainer} accessToken={true} />
            <PrivateRoute exact path={`${Routing.vehiclePromotion}${Routing.idOptional}`} component={VehiclesContainer} accessToken={true} />
            <PrivateRoute exact path={`${Routing.vehiclesAllStock}`} component={VehiclesContainer} accessToken={true} />
            <PrivateRoute exact path={`${Routing.vehicleDetail}${Routing.id}`} component={VehiclesDetailsContainer} accessToken={true} />

            {/** MSG CONFIRMATION **/}
            <PrivateRoute exact path={Routing.msgConfirmation} component={MessageConfirmation} accessToken={true} />

            {/** PAGE 404 **/}
            <PrivateRoute path='*' component={Page404Container} accessToken={true} />


          </Switch>

        {/** FOOTER **/}
        <Route component={FooterContainer}/>

        </ScrollToTop>

        {/** MODALS **/}
        <Toast/>
        <ModalBanner/>

      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ AppReducer }) => {
  const { maintenanceModeEnabled } = AppReducer;
  return {
    maintenanceModeEnabled,
  };
};

export default connect(mapStateToProps, null)(RouterComponent)
