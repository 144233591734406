import React, { Component } from 'react';
import { connect } from 'react-redux';
import {ImageSvg} from '../../components/ImageSvg';
import {getAsset} from '../../../../common/resources/assets/assets';
import {strings} from '../../../../common/resources/locales/i18n';


class MaintenancePage extends Component {

  render() {

    return (
      <>
        <main id="uc">
          <section id="under-construction">
            <div className="container-fluid text-center">
              <hr className="mini"/>
                <ImageSvg className="img-fluid" src={getAsset('icSymbolHyundai')} height={63} width={120} />
                  <h1 className="text-white my-4">{strings('maintenance.siteUnderMaintenance')}</h1>
                  <h2 className="text-white">{strings('maintenance.backSoon')}</h2>
                  <hr className="mini"/>
            </div>
          </section>
        </main>
      </>
    );
  }

}
/*
const mapStateToProps = ({ }) => {
  return {

  };
};

const mapStateToPropsActions = {

};
*/

export default connect(null, null)(MaintenancePage);
