
export default class ContactManager {

  static getIslandForDealer(dataIsland, dataDealers) {

    const result = []
    const isAddToResult = []

    if (dataIsland && dataIsland.length !== 0 && dataDealers && dataDealers.length !== 0){
      dataIsland.map((itemIsland) => {
        dataDealers.map((itemDealers) => {
          if (parseInt(itemIsland.value_id) === itemDealers.island_id && !isAddToResult.includes(itemIsland.value_id)) {
            result.push({ id: itemIsland.value_id, value: itemIsland.value_label })
            isAddToResult.push(itemIsland.value_id)
          }
        })
      })
    }

    return result;
  }

  static getDealerForIsland(dataDealers, id) {

    const result = []

    if (dataDealers && dataDealers.length !== 0 && id){
      dataDealers.map((item) => {
        if (item.island_id === parseInt(id)){
          result.push({ id: item.dealer_id, value: item.name  })
        }
      })
    }

    return result;
  }

  static getDealerForModels(dataModels) {

    const result = []

    if (dataModels && dataModels.length !== 0){
      dataModels.map((item) => {
        result.push({id: item.value_id, value: item.value_label})
      })
    }

    return result;
  }
}
