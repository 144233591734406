const urls = {
  '/testdrive/': '/contacto',
  '/contacto/': '/contacto',
  '/terminos-compra/': '/',
  '/politica-cookies/': '/',
  '/aviso-legal/': '/',
  '/politica-privacidad/': '/',
  '/models' : '/',
  '/models/' : '/',
  '/models/kona-2020/': '/vehiculos/kona',
  '/models/i10-2019/': '/',
  '/models/tucson-2020/': '/vehiculos/tucson',
  '/models/i20-2020/': '/vehiculos/i20',
  '/models/i30-2020/': '/vehiculos/i30',
  '/models/kona-hibrido-2020/': '/vehiculos/kona-hibrido',
  '/models/kona-electrico-2020/': '/vehiculos/kona-electrico',
  '/models/ioniq-5-2021/': '/vehiculos/ioniq5',
  '/models/i10-2019/307/': '/',
  '/models/ioniq-electrico-2019/': '/vehiculos/ioniq-electrico',
  '/models/tucson-2020/336/': '/vehiculos/tucson',
  '/models/i30-fastback-2020/': '/vehiculos/i30-fastback',
  '/models/i30-2020/330/': '/vehiculos/i30',
  '/models/kona-2020/358/': '/vehiculos/kona',
  '/models/kona-2020/351/': '/vehiculos/kona',
  '/models/i20-2020/343/': '/vehiculos/i20',
  '/models/tucson-hibrido-2020/': '/vehiculos/tucson-hibrido-enchufable',
  '/models/i20-2020/343/2966': '/vehiculos/i20',
  '/models/kona-2020/358/3053': '/vehiculos/kona',
  '/models/kona-2020/351/3233': '/vehiculos/kona',
  '/models/i20-2020/343/3127': '/vehiculos/i20',
  '/models/ioniq-hibrido-enchufable-2019/': '/vehiculos/ioniq-hibrido-enchufable',
  '/models/tucson-hibrido-enchufable-2020/': '/vehiculos/tucson-hibrido-enchufable',
  '/models/i30-fastback-2020/348/': '/vehiculos/i30-fastback',
  '/models/kona-electrico-2020/386/': '/vehiculos/kona-electrico',
  '/models/kona-hibrido-2020/392/': '/vehiculos/kona-hibrido',
  '/models/ioniq-5-2021/399/': '/vehiculos/ioniq5',
  '/models/kona-hibrido-2020/389/': '/vehiculos/kona-hibrido',
  '/models/kona-electrico-2020/388/': '/vehiculos/kona-electrico',
  '/models/i20-2020/343/3145': '/vehiculos/i20',
  '/models/kona-2020/351/3171': '/vehiculos/kona',
  '/models/kona-2020/358/3232': '/vehiculos/kona',
  '/models/i30-2020/330/3056': '/vehiculos/i30-fastback',
  '/models/kona-2020/351/3112': '/vehiculos/kona',
  '/models/tucson-hibrido-enchufable-2020/402/': '/vehiculos/tucson-hibrido-enchufable',
  '/models/kona-electrico-2020/388/3177': '/vehiculos/kona-electrico',
  '/models/kona-hibrido-2020/392/3096': '/vehiculos/kona-hibrido',
  '/models/kona-electrico-2020/388/3176': '/vehiculos/kona-electrico',
  '/models/kona-electrico-2020/388/3122': '/vehiculos/kona-electrico',
  '/models/tucson-hibrido-enchufable-2020/419/': '/vehiculos/tucson-hibrido-enchufable',
  '/models/i30-fastback-2020/348/3064': '/vehiculos/i30-fastback',
  '/models/ioniq-5-2021/399/3282': '/vehiculos/ioniq5',
  '/contacto/?desc=i20+FL+Trend&s=0': '/contacto',
  '/contacto/?desc=i20+FL+Classic&s=0': '/contacto',
  '/models/tucson-hibrido-enchufable-2020/402/3242': '/vehiculos/tucson-hibrido-enchufable',
  '/testdrive/cms/model/kona-ev?productCode=kona-ev&productCode=kona-ev': '/contacto',
};

export default urls;