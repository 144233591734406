export async function formatImageToSvg(html) {
  const t = jQuery(jQuery.parseHTML(html)[0]), n = t.attr("class"), i = t.attr("src");
  let svg ="";

  await jQuery.get(i, async function (i) {
    let o = jQuery(i).find("svg");
    o = o.attr("class", n + " replaced-svg")
    svg = o[0].outerHTML
  }, "xml")

  return svg
}

export function startCarousel() {
  jQuery(document).ready(function(){
    $('.card-product .owl-carousel').owlCarousel({
      loop: false,
      //margin: 0,
      nav: false,
      dots: true,
      items: 1
    });
    $('#product-info-slider').owlCarousel({
      loop: false,
      //margin: 0,
      nav: false,
      dots: true,
      items: 1
    });
  });

}

export function startSearchBox() {
  $("#icon-search").click(function () {
    $("#search-box").toggle();
    $("#nav-search").toggleClass("active");
  });
}

export function closeSearchBox() {
  $("#search-box").toggle();
  $("#nav-search").toggleClass("active");
}

export function startTooltips() {
  jQuery(document).ready(function(){
    $('[data-toggle="tooltip"]').tooltip();
  });
}

export function startSelectPicker() {
  jQuery(document).ready(function(){
    $('select').selectpicker();
  });
}

export function refreshSelectPicker() {
  jQuery(document).ready(function(){
    $('select').selectpicker('refresh');
  });
}

export function removeTooltips() {
  jQuery(document).ready(function () {
    $('body>.tooltip').remove();
  });
}

export function toggleModal (idElementToggle) {
  $(document).ready(function(){
    document.getElementById(idElementToggle).click();
  });
}

export function hiddenModal () {
  $(document).ready(function(){
    $('.modal-backdrop').removeClass('modal-backdrop fade show');
    $('.modal-open').removeClass('modal-open');
  });
}

export function closeModalBackdrop() {
  $(document).ready(function(){
    let body = document.getElementsByClassName("modal-open");
    if (body[0]) {
      body[0].classList.remove("modal-open");

      let modalBootstrap = document.getElementsByClassName("modal-backdrop fade show");
      modalBootstrap[0].remove();
    }
  });
}

export function startToast() {
  $(document).ready(function(){
    $('.toast').toast('show');
  });
}

export function clickElementByClass(className) {
  $(document).ready(function(){
    document.getElementsByClassName(className)[0].click();
  });
}

export function functionShowModalBanner(props) {
  const {time} = props;
  $(document).ready(function(){
    setTimeout(function() {
      $('#modalBanner').modal('show');
    }, time ? time : 4000);
  });
}

export function getDimensionElement(idElement) {
  const element = document.getElementById(idElement);
  if (element) return {x: element.offsetWidth, y: element.offsetHeight};
}