import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { applyMiddleware, compose, createStore} from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import rootReducer from '../../reducers/rootReducer';
import {isDev} from '../../utils/Utils';

const config = {
  key: 'root',
  storage,
  blacklist: ['ToastsReducer']


  // debug: true //to get useful logging
};

const reducers = persistReducer(config, rootReducer);

const composeEnhancers = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] && (isDev())
  ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({ shouldHotReload: false })
  : compose);

export default function configureStore() {

  const middleware   = (isDev()) ? [thunk, logger] : [thunk];
  const enhancers    = [applyMiddleware(...middleware)];
  const initialState = {};
  // if (__DEV__) middleware.push(logger); //Show logs on Chrome

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(...enhancers)
    // compose(...enhancers)
  );
  let persistor = persistStore(store);

  return { persistor, store };
}
