
export default class Types {

  /** UPDATE DATA **/
  static SET_COMPONENT_DATA = 'SET_COMPONENT_DATA';

  /** CLEAR DATA **/
  static CLEAR_COMPONENT_DATA = 'CLEAR_COMPONENT_DATA';
  
}
