export default class ModelsManager {

  static getBestFinalPrice = (data) => {
    return data && data.best_final_price ?
      data.best_final_price : '';
  };

  static getBestFinalPriceFormat = (data) => {
    return data && data.best_final_price_format ?
      data.best_final_price_format : '';
  };

  static getBestPrice = (data) => {
    return data && data.best_price ?
      data.best_price : '';
  };

  static getBestPriceFormat = (data) => {
    return data && data.best_price_format ?
      data.best_price_format : '';
  };

  static getDescription = (data) => {
    return data && data.description ?
      data.description : '';
  };

  static getId = (data) => {
    return data && data.id ?
      data.id: '';
  };

  static getImage = (data) => {
    return data && data.image ?
      data.image: '';
  };

  static getVehiclesCount = (data) => {
    return data && data.vehicles_count ?
      data.vehicles_count: 0;
  };

  static getBestCuotaPrice = (data) => {
    return data && data.best_cuota_price ?
      data.best_cuota_price : ""
  };

  static getImageAlt = data => data?.image_alt || ''; 

  static getBestCuotaPriceFormat = (data) => {
    return data && data.best_cuota_price_format ?
      data.best_cuota_price_format : ""
  };

  static getUrlKey = (data) => {
    return data && data.url_key ? data.url_key : "";
  };
}
