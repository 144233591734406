// Resources
import {strings} from '../../resources/locales/i18n';

// Utils
import {
  isChecked,
  isValidCardCVV,
  isValidCardExpirationDate,
  isValidCardNumber, isValidDni,
  isValidEmail,
  isValidName,
  isValidObject,
  isValidPassword,
  isValidPasswordCoincidence,
  isValidPhone,
  isValidPostalCode,
  isValidSelect,
  isValidString,
  isValidStringWithoutNumber,
  isValidTextArea
} from './FormValidation';

export default class FormValidationManager {

  static formLogin = (props) => {
    const { username, password } = props;
    const error = [];

    if (!isValidEmail(username.trim())) {
      error.push({ key: 'username', value: strings('login.error.invalidEmailFormat') });
    }

    if (!isValidPassword(password)) {
      error.push({ key: 'password', value: strings('login.error.invalidPasswordLength') });
    }

    if (error.length !== 0) {
      return error
    }

    // Validation OK
    return null;
  };

  static formContact = (name, surnames, email, phone, island, concessionaire, model) => {
    const error = [];

    if (!isValidStringWithoutNumber(name.trim())) {
      error.push({ key: 'name', value: strings('error.completeThisField') });
    }
    if (!isValidStringWithoutNumber(surnames.trim())) {
      error.push({ key: 'surnames', value: strings('error.completeThisField') });
    }
    if (!isValidEmail(email.trim())) {
      error.push({ key: 'email', value: strings('error.completeThisField') });
    }
    if (!isValidPhone(phone.trim())) {
      error.push({ key: 'phone', value: strings('error.completeThisField') });
    }
    if (island === '') {
      error.push({ key: 'island', value: strings('error.completeThisField') });
    }
    if (concessionaire === '') {
      error.push({ key: 'concessionaire', value: strings('error.completeThisField') });
    }
    if (model === '') {
      error.push({ key: 'model', value: strings('error.completeThisField') });
    }

    return error;

    /*if (error.length === 0) {
      return true;
    }*/
  };

  static formReservation = (props) => {
    const {concessionaire, email, name, island, surnames, phone} = props;
    const error = [];

    if (!isValidStringWithoutNumber(name.trim())) {
      error.push({ key: 'name', value: strings('error.completeThisField') });
    }
    if (!isValidStringWithoutNumber(surnames.trim())) {
      error.push({ key: 'surnames', value: strings('error.completeThisField') });
    }
    if (!isValidEmail(email.trim())) {
      error.push({ key: 'email', value: strings('error.completeThisField') });
    }
    if (!isValidPhone(phone.trim())) {
      error.push({ key: 'phone', value: strings('error.completeThisField') });
    }
    if (island === '') {
      error.push({ key: 'island', value: strings('error.completeThisField') });
    }
    if (concessionaire === '') {
      error.push({ key: 'concessionaire', value: strings('error.completeThisField') });
    }

    return error;
  };

}
