import React from 'react';
import {Route, Redirect, useParams, useLocation} from 'react-router-dom';
import Routing from './Routing';
import urls from './urlsIndexed';

const PrivateRoute = ({component: Component, rest, accessToken, redirect, path, typeView, maintenance}) => {
  const location = useLocation();


  const keyFind = Object.keys(urls).find(key => {
    if(key == location.pathname) {
      return key;
    }
  });

  if (keyFind) {
    return (<Redirect to={urls[keyFind]} />);
  }

  if (maintenance) {
    return (<Redirect to={Routing.maintenancePage} />)
  }

  if (!accessToken) {
    return (<Redirect to={redirect ? redirect : Routing.login} />)
  }

  if((path !== Routing.address && path !== Routing.login) && accessToken && accessToken.length > 0){
    return (<Redirect to={Routing.address} />)
  }

  return (
    <Route {...rest} path={path} render={props => <GetUseParamsView component={Component} props={props} typeView={typeView}/>} />
  );
};

function GetUseParamsView({component: Component, props, typeView}) {
  let { id } = useParams();
  let queryParams = getQueryParams();

  return <Component idParams={id} {...props} typeView={typeView} queryParams={queryParams}/>;
}

function getQueryParams() {
  return new URLSearchParams(useLocation().search);
}


export default PrivateRoute;
