
export default class Types {

  /** CLEAR VEHICLES **/
  static CLEAR_VEHICLE = 'CLEAR_VEHICLE';
  static CLEAR_VEHICLES = 'CLEAR_VEHICLES';
  static CLEAR_FINANCING_SIMULATOR = 'CLEAR_FINANCING_SIMULATOR';
  static CLEAR_RESERVATION = 'CLEAR_RESERVATION';

  /** GET VEHICLES **/
  static GET_VEHICLE_FAILED = 'GET_VEHICLE_FAILED';
  static GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';

  /** GET VEHICLES **/
  static GET_VEHICLES_FAILED = 'GET_VEHICLES_FAILED';
  static GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';

  /** UPDATE STATE **/
  static SET_VEHICLES_STATE = 'SET_VEHICLES_STATE';
}
