import React, { Component } from 'react';
import { connect } from 'react-redux';
import Routing from '../routing/Routing';

//Actions
import {
  apiPostContact,
  clearDataContact,
  setDataContact,
  validateForm,
} from '../../../../common/modules/contact/ContactActions';

//Components
import TextInput from '../../components/TextInput';
import CheckboxInput from '../../components/CheckboxInput';
import SelectInput from '../../components/SelectInput';
import ModalCalc from './ModalCalc';

//Resources
import { strings } from '../../../../common/resources/locales/i18n';
import { Link } from 'react-router-dom';
import {
  startSelectPicker,
  refreshSelectPicker
} from '../../resources/styles/js/custom';
import ContactManager from '../../../../common/modules/contact/ContactManager';
import { legalScripts_Default, isPro, addPixelFacebook } from '../../../../common/utils/Utils';
import InfoReceiveCommunications from './modals/InfoReceiveCommunications';
import InfoStatisticalAnalysis from './modals/InfoStatisticalAnalysis';

class ContactContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      readMore: false,
    }
  }

  async componentDidMount() {
    const { metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId } = this.props;

    if (isPro() && addPixelFacebook) addPixelFacebook(false, false);

    if (legalScripts_Default) legalScripts_Default({metasHomeTitle, metasHomeDescription, cookiesBannerScript, metricsGtmEnabled, metricsGtmId});

    this._clearDataContact()
    startSelectPicker()
  }

  render() {
    const {
      isLoadingContact, name, surnames, email, phone, error, contactSideMessage,
      submit, island, concessionaire, islands, dataDealers, comunicationsAccepted,
      analysisAccepted,
      contactModelsOptions, model, rgpdPrivacyPolicyLink,contactHeaderImage,
    } = this.props;

    const {readMore} = this.state;


    return (
      <>
        <header id="header" className="bg-primary header-contact" style={{backgroundImage: `url(${contactHeaderImage})`}}>
          <div className="container text-light text-center">
            <h1 className="text-light">{strings('contact.contactAdvisor')}</h1>
          </div>
        </header>

        <main id="main" role="main">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="h4 mb-4">{strings('contact.askInformationServices')}</div>
                  <p>{contactSideMessage}</p>
                </div>
                <div className="col-md-8 offset-md-1">
                  <form onSubmit={(e) => this._handlePostContact(e)} className="mt-4 mt-lg-0 mb-3" noValidate>
                    {/* START LEGAL TEXT */}
                    <label className="h5">Solicitud de información</label>
                    <span>
                      <span className="d-block h6"> <u> Información básica sobre Protección de Datos: </u> <br/></span>
                      Responsable: HYUNDAI CANARIAS S.L.U {' '}
                      <a href="https://www.hyundai.com/canarias/es/utilidades/politica-de-privacidad?_ga=2.122191691.1759595897.1632903868-372755064.1632903868" target="_blank" rel='noreferrer'>
                        (+Info)<br/>
                      </a>
                      Finalidad: Gestión de consultas y solicitudes<br/>de información. {' '}
                      <a href="https://www.hyundai.com/canarias/es/utilidades/politica-de-privacidad" target="_blank" rel='noreferrer'>
                        (+Info)<br/>
                      </a>
                      Derechos: puede ejercitar su derecho de acceso, rectificación, supresión y otros, tal como aparece en la información ampliada que puede conocer visitando nuestra {' '}
                      <a href="https://www.hyundai.com/canarias/es/utilidades/politica-de-privacidad" rel='noreferrer' target="_blank">
                        política de privacidad
                      </a>.
                    </span>
                    {/* END LEGAL TEXT */}
                    <div className="row mt-3">
                      {/** Check this component **/}
                      <TextInput
                        id='name'
                        type='text'
                        placeholder={strings('placeholder.name')}
                        onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                        value={name}
                        error={error}
                        textLabel={strings('placeholder.name')}
                        submit={submit}
                        styleContainer='col-md-6'
                        isFormGroup={true}
                      />

                      <TextInput
                        id='surnames'
                        type='text'
                        placeholder={strings('placeholder.surnames')}
                        onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                        value={surnames}
                        error={error}
                        textLabel={strings('placeholder.surnames')}
                        submit={submit}
                        styleContainer='col-md-6'
                        isFormGroup={true}
                      />

                      <TextInput
                        id='email'
                        type='email'
                        placeholder={strings('placeholder.email')}
                        onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                        value={email}
                        error={error}
                        textLabel={strings('placeholder.email')}
                        submit={submit}
                        styleContainer='col-md-6'
                        isFormGroup={true}
                      />

                      <TextInput
                        id='phone'
                        type='tel'
                        placeholder={strings('placeholder.phone')}
                        onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                        value={phone}
                        error={error}
                        textLabel={strings('placeholder.phone')}
                        submit={submit}
                        styleContainer='col-md-6'
                        isFormGroup={true}
                      />

                      <SelectInput
                        title={strings('placeholder.island')}
                        dataChildren={ContactManager.getIslandForDealer(islands, dataDealers)}
                        showSelect={true}
                        titleSelect={strings('placeholder.selectIsland')}
                        id='island'
                        value={island}
                        onChange={(e) => {
                          this._handleValueInput(e.target.id, e.target.value)
                          refreshSelectPicker()
                        }}
                        error={error}
                        submit={submit}
                        styleContainer={'col-md-6'}
                        getItemId={true}
                      />

                      <SelectInput
                        title={strings('placeholder.concessionaire')}
                        dataChildren={ContactManager.getDealerForIsland(dataDealers, island)}
                        showSelect={true}
                        titleSelect={strings('placeholder.selectConcessionaire')}
                        id='concessionaire'
                        value={concessionaire}
                        onChange={(e) => this._handleValueInput(e.target.id, e.target.value)}
                        error={error}
                        submit={submit}
                        styleContainer={'col-md-6'}
                        getItemId={true}
                      />

                      <SelectInput
                        title={strings('placeholder.model')}
                        dataChildren={ContactManager.getDealerForModels(contactModelsOptions)}
                        showSelect={true}
                        titleSelect={strings('placeholder.selectModel')}
                        id='model'
                        value={model}
                        onChange={(e) => {
                          this._handleValueInput(e.target.id, e.target.value)
                          refreshSelectPicker()
                        }}
                        error={error}
                        submit={submit}
                        styleContainer={'col-md-6'}
                        getItemId={true}
                      />

                    </div>

                    <div className="mt-4 mb-4">
                      {/* START LEGAL TEXT */}
                      <div className={`mb-3 ${readMore ? 'd-none' : 'd-block'}`}>
                        <span className={`${readMore ? 'd-none' : 'd-block'}`}>
                          <span className='font-weight-bold'>¿Quieres ser parte del Ecosistema de Domingo Alonso Group*? {' '}</span>
                          <Link 
                            replace 
                            to={Routing.nowhere} 
                            onClick={() => this._handleValueChangeView('readMore', !readMore)} 
                          >
                            Ver más.
                          </Link>
                        </span>
                      </div>
                      <div className={`mb-3 ${readMore ? 'd-block' : 'd-none'}`}>
                        <span className={`${readMore ? 'd-block' : 'd-none'}`}>
                          <span className='font-weight-bold'>¿Quieres ser parte del Ecosistema de Domingo Alonso Group*? {' '}</span>
                          Para DOMINGO ALONSO GROUP, tú, nuestro cliente, eres el centro de lo que hacemos. Queremos contar contigo 
                          para mejorar nuestros servicio y ofrecerte cada día mejores ofertas y promociones, incluso personalizadas,
                          para invitarte a eventos y actividades y en general mantenerte informado sobre la actividad de nuestro grupo. {' '}
                          <Link 
                            replace 
                            to={Routing.nowhere}
                            onClick={() => this._handleValueChangeView('readMore', !readMore)} 
                          >
                            Ocultar.
                          </Link>
                        </span>
                      </div>
                    
                      <div className="form-group mt-3">
                        <CheckboxInput
                          containLabel={strings('contact.receiveCommercialCommunications')}
                          id='comunicationsAccepted'
                          error={error}
                          extraElement={<>&nbsp;<i data-toggle="modal" data-target="#modalInfoRecibirComunicaciones" className="bi bi-info-circle-fill"></i></>}
                          submit={submit}
                          checked={comunicationsAccepted}
                          onChange={(e) => this._handleValueInput(e.target.id, e.target.checked)}
                          notCheck={true}
                        />
                        <CheckboxInput
                          containLabel={strings('contact.customCommercialCommunications')}
                          id='analysisAccepted'
                          error={error}
                          submit={submit}
                          extraElement={<>&nbsp;<i data-toggle="modal" data-target="#modalInfoAnalisisEstadisticos" className="bi bi-info-circle-fill"></i></>}
                          checked={analysisAccepted}
                          onChange={(e) => this._handleValueInput(e.target.id, e.target.checked)}
                          notCheck={true}
                        />
                      </div>

                      <span>
                        <span className="d-block h6"> <u> Información básica sobre Protección de Datos: </u> <br/></span>
                        Responsable: DOMINGO ALONSO GROUP S.L. {' '}
                        <a href={rgpdPrivacyPolicyLink} target="_blank" rel='noreferrer'>
                          (+Info)<br/>
                        </a>Finalidad: Envío de información publicitaria y elaboración de perfiles comerciales. {' '}
                        <a href={rgpdPrivacyPolicyLink} target="_blank" rel='noreferrer'>
                          (+Info)<br/>
                        </a>
                        Derechos: puede ejercitar su derecho de acceso, rectificación, supresión y otros, tal como aparece en la información ampliada que puede conocer en nuestra web {' '}
                        <a href="https://www.domingoalonsogroup.com/" target="_blank" rel='noreferrer'>
                          www.domingoalonsogroup.com
                        </a>.
                      </span>
                      <strong className='d-block mt-3'>*Este apartado no es indispensable para prestarle los servicios solicitados.</strong>
                    {/* END LEGAL TEXT */}
                      {/* <div className="mb-2 text-dark h6">Información básica de protección de datos</div>
                      <p className="text-gray-500 mb-3">
                        Responsable: DOMINGO ALONSO, S.L.U.<br />
                        Finalidad: Gestión de consultas y solicitudes de información.<br />
                        Derechos: Puede ejercitar su derecho de acceso, rectificación, supresión y otros, tal como aparece en la información ampliada que puede conocer visitando nuestra Política de Privacidad.
                      </p>
                      <p className="text-gray-500">Antes de aceptar la solicitud, debes leer toda la información básica y adicional que podrá acceder dentro de la Política de Privacidad.</p> */}
                      
                    </div>

                    <button type="submit" className="btn btn-lg btn-primary px-5" disabled={isLoadingContact} style={{ maxHeight: 50, minWidth: 250 }}>
                      {isLoadingContact
                        ? <div className="d-flex align-items-center justify-content-center">
                          <div className="spinner-border" role="status" aria-hidden="true" style={{ width: 25, height: 25 }} />
                        </div>
                        : null
                      }
                      {!isLoadingContact ? strings('button.sendRequest') : ''}
                    </button>

                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
        <ModalCalc />
        {this._renderModalInfo()}
      </>
    )
  }

  /** PRIVATE METHODS **/
  _clearDataContact() {
    this.props.clearDataContact()
  }

  _handlePostContact(e) {
    e.preventDefault();
    this.props.apiPostContact((response) => this._redirectSuccess(response));
  }

  _handleValueChangeView(prop, value) {
    this.setState({[prop]: value});
  }

  _handleValueInput(prop, value) {
    const { setDataContact, submit, validateForm } = this.props;
    setDataContact({ prop, value });
    if (submit) validateForm();
  }

  _redirectSuccess = (response) => {
    const { history } = this.props;
    history.push({
      pathname: Routing.msgConfirmation,
      state: { message: response }
    });
  };

  _renderModalInfo() {
    return (
      <>
        <InfoReceiveCommunications />
        <InfoStatisticalAnalysis/>
      </>
    )
  }
}

const mapStateToProps = ({ AppReducer, ContactReducer }) => {
  const { contactHeaderImage, financeExampleLanding, islands, dataDealers, contactSideMessage, metasHomeTitle, metasHomeDescription, cookiesBannerScript, contactModelsOptions, rgpdPrivacyPolicyLink, metricsGtmEnabled, metricsGtmId} = AppReducer
  const { isLoadingContact, name, surnames, email,
    phone, island, concessionaire, politics, error, submit, comunicationsAccepted, analysisAccepted, model, } = ContactReducer;

  return {
    contactHeaderImage, financeExampleLanding, islands, dataDealers, contactSideMessage, metasHomeTitle, metasHomeDescription, cookiesBannerScript, contactModelsOptions, rgpdPrivacyPolicyLink, metricsGtmEnabled, metricsGtmId,
    isLoadingContact, name, surnames, email,
    phone, island, concessionaire, politics, error, submit, comunicationsAccepted, analysisAccepted, model,
  };

};

const mapStateToPropsActions = {
  apiPostContact,
  clearDataContact,
  setDataContact,
  validateForm,
};

export default connect(mapStateToProps, mapStateToPropsActions)(ContactContainer);
