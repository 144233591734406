import React, { Component } from 'react';
import { connect } from 'react-redux';

//Actions
import { deleteElementById } from '../../../../common/modules/toasts/ToastsActions';

//Components
import { ImageSvg } from '../../components/ImageSvg';

//Resources
import ToastsManager from '../../../../common/modules/toasts/ToastsManager';

class Toast extends Component {

  render () {
    const { dataToasts } = this.props;

    return (
      <div className="position-fixed p-3" style={{ zIndex: 9999, left: 0, bottom: 0 }}>
        {this.renderToasts(dataToasts)}
      </div>
    )
  }

  renderToasts(dataToasts) {

    return (
      dataToasts.map((item, index) => {
        return this.renderToastsItem(item, index)
      })
    )
  }

  renderToastsItem(item, index){
    const { body, icon, title, idToast } = item;

    return (
      <div key={index} id={idToast} className="toast" role="alert" aria-live="assertive" aria-atomic="true" data-autohide='false'>
        <div className="toast-header">
          {icon
            ? <div className='mr-2'>
              <ImageSvg src={icon} />
            </div>
            : <></>}
          <strong className="mr-auto">{title}</strong>
          <button
            type="button"
            className="ml-3 mb-1 close"
            data-dismiss="toast"
            aria-label="Close"
            onClick={() => this._deleteElement(ToastsManager.getId(item), 'dataToasts')}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body" style={{backgroundColor: 'rgba(255,255,255,.75)'}}>
          {body}
        </div>
      </div>
    )
  }

  /** PRIVATE METHODS **/
  _deleteElement(id, prop) {
    this.props.deleteElementById({id, prop, clickRemoveToast: true});
  }

}

const mapStateToProps = ({ToastsReducer}) => {
  const { dataToasts } = ToastsReducer;

  return {
    dataToasts,
  };
}

const mapStateToPropsAction = {
  deleteElementById,
};

export default connect(mapStateToProps, mapStateToPropsAction)(Toast);
