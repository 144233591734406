
export default class Types {

  /** GLOBAL CONFIG **/
  static GET_MODELS_HOME_FAILED = 'GET_MODELS_HOME_FAILED';
  static GET_MODELS_HOME_SUCCESS = 'GET_MODELS_HOME_SUCCESS';

  /** UPDATE STATE **/
  static SET_MODELS_STATE = 'SET_MODELS_STATE';
}
