import Types from './Types';

const INITIAL_STATE = {
  availableFilters: null,
  colors: [],
  concessionaire: '',
  currentModelDescription: "",
  email: '',
  fuels: [],
  dataVehicles: [],
  dataVehicleDetail: null,
  error: [],
  island: '',
  isLoadingReservation: false,
  isLoadingVehicle: false,
  isLoadingVehicles: false,
  vehiclesCount: 0,
  maxPrice: 0,
  minPrice: 0,
  name: '',
  rgpd: false,
  specialPrice: false,
  searchBox: '',
  submit: false,
  surnames: '',
  phone: '',
  price:0,
  cuota: 0,
  transmissions: [],
  maxCuota: 0,
  minCuota: 0,
  comunicationsAccepted: false,
  analysisAccepted: false,
  dataProtectAccepted: true,
  initialEntry: 0,
  postponement: 0,
  takeOutInsurance: false,
  interestingOption: false,
  isLoadingFinancing: false,
  resultFinancing: [],
  currentModelH2Label: '',
  h2ModelBottom: '',
  currentModelH2Paragraph: '',
  currentModelH1: '',
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case Types.CLEAR_FINANCING_SIMULATOR:
      return {...state, resultFinancing: [], initialEntry: 0, postponement: 0}

    /** CLEAR VEHICLES **/
    case Types.CLEAR_VEHICLE:
      return {...state, dataVehicleDetail: null }

    case Types.CLEAR_VEHICLES:
      return {...INITIAL_STATE, colors: []}

    case Types.CLEAR_RESERVATION:
      return {
        ...state,
        island: INITIAL_STATE.island,
        concessionaire: INITIAL_STATE.concessionaire,
        email: INITIAL_STATE.email,
        error: INITIAL_STATE.error,
        isLoadingReservation: INITIAL_STATE.isLoadingReservation,
        name: INITIAL_STATE.name, rgpd: INITIAL_STATE.rgpd,
        submit: INITIAL_STATE.submit,
        surnames: INITIAL_STATE.surnames,
        phone: INITIAL_STATE.phone
      };

    /** GET VEHICLE **/
    case Types.GET_VEHICLE_SUCCESS:
      return {...state,
        dataVehicleDetail: action.payload
      }

    case Types.GET_VEHICLE_FAILED:
      return {...state,
        dataVehicleDetail: null
      }

    /** GET VEHICLES **/
    case Types.GET_VEHICLES_SUCCESS:
      return {...state,
        currentModelH1: action?.payload?.current_model_h1,
        currentModelH2Label: action.payload?.current_model_h2_label,
        currentModelH2Paragraph: action.payload?.current_model_h2_paragraph,
        currentModelDescription: action.payload.current_model_description,
        h2ModelBottom: action?.payload?.h2_model_bottom,
        availableFilters: action.payload.available_filters,
        dataVehiclesOriginal: action.payload.vehicles_list,
        dataVehicles: action.payload.vehicles_list,
        maxPrice: action.payload.max_price,
        minPrice: action.payload.min_price,
        maxCuota: action.payload.max_cuota,
        minCuota: action.payload.min_cuota,
        cuota: state.cuota === 0 ? undefined : state.cuota,
        price: state.price === 0 ? undefined : state.price,
        vehiclesCount: action.payload.vehicles_count,
      };

    /** UPDATE STATE **/
    case Types.SET_VEHICLES_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
