
import {strings} from '../../../../../common/resources/locales/i18n';
import React from 'react';
import {AsyncImage} from '../../../components/AsyncImage';
import {getAsset} from '../../../../../common/resources/assets/assets';
import {Link} from 'react-router-dom';
import Routing from '../../routing/Routing';
import { addPixelFacebook, getVinPixelFacebook, isPro } from '../../../../../common/utils/Utils';


const HeaderVehiclesComponents = (props) => {
  const {
    removeComparator, name, image, finalPriceFormat, cuotaPriceFormat,
    gearboxDescription, fuelDescription, colorDescription, id, showModal, modelGroupDescription, item
  } = props;

  return (
    <td className="px-3 pb-4 td-car border-0" >
      <div className="position-relative">
        <div className="position-absolute top-0 right-0" style={{zIndex: 999}}>
          <Link replace to={Routing.nowhere} onClick={() => removeComparator()} className="text-muted lead" data-toggle="tooltip" data-placement="top"
             title={strings('tooltip.removeComparer')}>
            <ion-icon name="close-circle-outline"/>
          </Link>
        </div>
        <AsyncImage
          source={image}
          style='img-fluid mb-3'
          srcPlaceholder={getAsset('icPlaceholderModel')}
          styleImg={{width: '100%'}}
          alt={modelGroupDescription}
        />

      </div>
      <div className="h4 product-title fs-sm">
        <Link to={`${Routing.vehicleDetail}${id}`} >{name}</Link>
      </div>
      <p className="mb-1 small text-muted">
        {`
          ${gearboxDescription} ${gearboxDescription !== '' ? '/' : ''}
          ${fuelDescription} ${fuelDescription !== '' ? '/' : ''}
          ${colorDescription}`
        }
      </p>

      <div className="row mt-3">
        <div className="col">
          <p className="mb-0 small">{strings('models.from')}<br/>
            <span className="h5">{cuotaPriceFormat} <small>{strings('models.from2')}</small></span>
          </p>
        </div>
        <div className="col">
          <p className="mb-0 small">{strings('models.pvp')}<br/><span className="h5">{finalPriceFormat}</span></p>
        </div>
      </div>
      <Link 
        to={Routing.nowhere} 
        onClick={() => {
          showModal();
          if (isPro() && addPixelFacebook) addPixelFacebook(false, true, getVinPixelFacebook(item));
        }}  
        className="btn btn-primary d-block w-100 mt-3" 
        role="button" 
        data-toggle="modal"
        data-target="#modalReservation">{strings('button.imInterested')}
      </Link>
    </td>
  )
}

export default HeaderVehiclesComponents
