import Types from './Types';

const INITIAL_STATE = {
  isLoadingContact: false,
  name: '',
  surnames: '',
  email: '',
  phone: '',
  island: '',
  concessionaire: '',
  model: '',
  politics: false,
  comunicationsAccepted: false,
  analysisAccepted: false,
  dataProtectAccepted: true,
  validate: false,
  error: [],
  submit: false,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    case Types.ACTION_FORM_CONTACT:
      return {...state};

    case Types.CLEAR_DATA_CONTACT:
      return{...state, ...INITIAL_STATE};

    case Types.SET_DATA_CONTACT:
      return {...state, [action.payload.prop]: action.payload.value};

    default:
      return state;
  }
}
