import Types from './Types';

const INITIAL_STATE = {
  dataToasts: [],
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** UPDATE DATA **/
    case Types.SET_COMPONENT_DATA:
      return {...state, [action.payload.prop]: action.payload.value};

    /** CLEAR DATA **/
    case Types.CLEAR_COMPONENT_DATA:
      return {...state, ...INITIAL_STATE};

    default:
      return state;
  }
};
