var initESW = function(gslbBaseURL) {
  window.embedded_svc.settings.displayHelpButton = true; //Or false
  window.embedded_svc.settings.language = 'es'; //For example, enter 'en' or 'en-US'
 
  window.embedded_svc.settings.extraPrechatFormDetails = [
  {
      "label": "Nombre",
      "transcriptFields": ["CustomerName__c"],
      "displayToAgent":true
  },
  {
      "label": "Apellidos",
      "transcriptFields": ["CustomerLastname__c"],
      "displayToAgent":true
  },
  {
      "label": "Correo electrónico",
      "transcriptFields": ["CustomerEmail__c"]
  },
{
      "label": "Tipo de consulta",
      "transcriptFields": ["CustomerProblem__c"]
  },
  {
      "label": "Consiento que traten mis datos",
      "transcriptFields": ["CustomerConsentimiento__c"]

  }

]; 

// disable creation of a contact and a case:
// this will be handled by the chat transcript trigger that will create a case that will create a contact
window.embedded_svc.settings.extraPrechatInfo = [{
  "entityName":"Contact",
  "entityFieldMaps": [{
      "doCreate":false,
      "doFind":false,
      "fieldName":"Nombre",
      "isExactMatch":false,
      "label":"Nombre"
      },
{
      "doCreate":false,
      "doFind":false,
      "fieldName":"Apellidos",
      "isExactMatch":false,
      "label":"Apellidos"
     }, 
     {
      "doCreate":false,
      "doFind":true,
      "fieldName":"Correo electrónico",
      "isExactMatch":false,
      "label":"Correo electrónico"
    }]
}, 
{
     "entityName":"Case",
"entityFieldMaps": [{
      "doCreate":false,
      "doFind":false,
      "fieldName":"Tipo de consulta",
      "isExactMatch":false,
      "label":"Tipo de consulta"
      },
{
      "doCreate":false,
      "doFind":false,
      "fieldName":"Consiento que traten mis datos",
      "isExactMatch":false,
      "label":"Consiento que traten mis datos"
     }]
}
];

window.embedded_svc.settings.defaultMinimizedText = 'Chat'; //(Defaults to Chat with an Expert)
window.embedded_svc.settings.disabledMinimizedText = 'Chat'; //(Defaults to Agent Offline)
window.embedded_svc.settings.offlineSupportMinimizedText = 'Déjanos tu consulta';
window.embedded_svc.settings.loadingText = 'Cargando'; //(Defaults to Loading)
  //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

  // Settings for Chat
  //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
      // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
      // Returns a valid button ID.
  //};
  //embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
  //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
  //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
  
  /*embedded_svc.settings.extraPrechatFormDetails = [{"label":"Email", "displayToAgent": true, "transcriptFields": ["CustomerEmail__c"]},
                                                   {"label":"LastNameme", "displayToAgent": true, "transcriptFields": ["CustomerName__c"]}
                                                   ];
  
*/
  /* embedded_svc.settings.extraPrechatFormDetails = [{
 'label': 'Email', 
   'value': '',
   'displayToAgent': true,
   'saveToTranscript': 'CustomerEmail__c'
}];*/
window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
window.embedded_svc.settings.entryFeature = 'LiveAgent';

window.embedded_svc.init(
'https://domingoalonso--uat.my.salesforce.com',
'https://uat-domingoalonso.cs89.force.com/liveAgentSetupFlow',
gslbBaseURL,
'00D0E000000DnY8',
'Configurador_Hyundai_Canarias',
{
  baseLiveAgentContentURL: 'https://c.la2-c1cs-cdg.salesforceliveagent.com/content',
  deploymentId: '5720E000000CbK9',
  buttonId: '5730E0000008Pe0',
  baseLiveAgentURL: 'https://d.la2-c1cs-cdg.salesforceliveagent.com/chat',
  eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I0E00000000QYUAY_17178cb880b',
  isOfflineSupportEnabled: true
}
);
};

export function loadChatDev()  {
  if (!window.embedded_svc) {
  var s = document.createElement('script');
  s.setAttribute('src', 'https://domingoalonso--uat.my.salesforce.com/embeddedservice/5.0/esw.min.js');
  s.onload = function() {
  initESW(null);
  };
  document.body.appendChild(s);
  } else {
  initESW('https://service.force.com');
  }
}

