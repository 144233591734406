import Types from './Types';
import {parseStringVar} from '../../utils/Utils';

const INITIAL_STATE = {
  maintenanceModeEnabled: false,
  showModalBanner: false,
  showModalBannerTimestamp: null,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** GET CONFIG **/
    case Types.GET_DEALERS_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_DEALERS_SUCCESS:


      return {...state, dataDealers: action.payload.items };

    case Types.GET_CONFIG_FAILED:
      return { ...state, error: action.payload };

    case Types.GET_CONFIG_SUCCESS:

      const data = { }

      action.payload.items.map((item) => {
        data[parseStringVar(item.config_key)] = item.config_options ? item.config_options : item.config_value
      })

      return {...state, ...data, maintenanceModeEnabled: false};

    /** UPDATE STATE **/
    case Types.SET_LOADING_APP_CONFIG:
      return { ...state, isLoading: action.payload };

    case Types.SET_APP_CONFIG_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
