import React, {Component} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import configureStore from '../../common/services/store/store';
import AppContainer from './module/app/AppContainer';
import {initTranslations} from '../../common/resources/locales/i18n';


/*Load css and js*/
import '../public/js/lib/popper.min';
import '../public/js/plugins/owl-carousel/owl.carousel.min.js';
import '../public/js/form-validation';
import './resources/styles/js/app';
import '../public/css/bootstrap.min.css';

import '../public/css/owl.carousel.min.css';
import '../public/css/owl.theme.default.min.css';
import './resources/styles/css/style.css';

/*custom css and js*/
import './resources/styles/css/custom.css'
import './resources/styles/css/fonts.css'
import './resources/styles/js/custom';


const { persistor, store } = configureStore();

class App extends Component  {

  async componentDidMount () {
    await initTranslations();
  }

  render(){
    return (
      <Provider store={ store }>
        <PersistGate
          persistor={ persistor }>
          <AppContainer/>
        </PersistGate>
      </Provider>
    )
  }
}

export default App;
