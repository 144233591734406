import React, { Component } from 'react'
import { connect } from 'react-redux'

//resources
import { strings } from '../../../../../common/resources/locales/i18n'

class InfoReceiveCommunications extends Component {
  render() {
    const { openModalReservation } = this.props
    return (
      <>
        <div className="modal fade" id="modalInfoRecibirComunicaciones" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content">

              <div className="modal-header d-flex align-items-center bg-light">
                <div className="h5 modal-title m-0">
                  RECIBIR COMUNICACIONES COMERCIALES Y DE FIDELIZACIÓN
                </div>
                {openModalReservation ? (
                  <button type="button" className="close p-3" onClick={() => setTimeout(() => document.getElementById('open-modal-reservation')?.click(), 500)} data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                ) : (
                  <button type="button" className="close p-3" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                )}
              </div>

              <div className="modal-body">
                  <p>El Consentimiento para las Comunicaciones Comerciales, las hemos dividido en tres
                      partes, Venta, Postventa y Eventos y Acciones Promocionales, con el fin de permitirte
                      personalizar tu interés por actividades a la hora de recibir estas comunicaciones
                      comerciales. Marcando esta opción, prestas tu consentimiento, para que todos los datos
                      de carácter personal de los que disponemos (datos de contacto, DNI, fecha de
                      nacimiento, correo electrónico, tipo de carnet de conducir, matrícula, aficiones e
                      intereses) pasen a formar parte de una Entidad dentro del Grupo de Empresas, llamada
                      DOMINGO ALONSO GROUP, S.L.U. con CIF B76078104, y domicilio en la Avda. Pintor
                      Felo Monzon, 34. C.P. 35019. Las Palmas de Gran Canaria. Las Palmas. España, con la
                      finalidad de centralizar todas las comunicaciones comerciales y de fidelización, a través
                      de cualquier medio multicanal (teléfono, correo electrónico, correo postal, SMS, mms,
                      whatsApp, etc...). Todas estas comunicaciones que te sean remitidas estarán relacionadas
                      con el mundo de la automoción. Criterios de conservación de los datos: se conservarán
                      mientras exista un interés mutuo para mantener el fin del tratamiento y cuando ya no
                      sea necesario para tal fin, se suprimirán con medidas de seguridad adecuadas para
                      garantizar la seudonimización de los datos o la destrucción total de los mismos.
                      Comunicación de los datos: no se comunicarán los datos a terceros, salvo obligación
                      legal. En este caso, informar que DOMINGO ALONSO GROUP, S.L., pertenece a un
                      Grupo Empresarial y existe un interés legítimo en transmitir datos personales dentro del
                      mismo para fines administrativos internos, incluido el tratamiento de datos personales
                      de clientes. Por lo que cualquier concesionario que haya sido identificado como
                      preferido o haya intervenido en el proceso de compra, podrá disponer de sus datos y
                      comunicarse contigo. En las distintas webs del grupo, podrás acceder a la información
                      acerca del mismo. Y, si deseas que alguna empresa reflejada dentro del Grupo
                      Empresarial no acceda a tus datos, nos lo puedes comunicar.</p>
                  <p>Todos los consentimientos son orientados a ésta entidad, <strong>DOMINGO ALONSO
                          GROUP, S.L.</strong> En caso de no aceptar esta finalidad, no podremos seguir enviándote
                      comunicaciones comerciales ni ofertas a medida, así como promociones, sorteos o
                      regalos directos que se realicen. Te recordamos que, en todas las comunicaciones
                      comerciales, realizadas por <strong>DOMINGO ALONSO GROUP, S.L.</strong>, siempre te damos la
                      opción de darte de baja. Te recordamos que tu consentimiento, tiene carácter de
                      revocable. Puedes revocarlo cuando lo desees con efecto inmediato, contactando con el
                      servicio de Atención al Cliente, al correo protecciondatos@domingoalonsogroup.com, y
                      al teléfono 828 701 208</p>
              </div>

              <div className="modal-footer">
              {openModalReservation ? (
                <button type="button" data-dismiss='modal' onClick={() => setTimeout(() => document.getElementById('open-modal-reservation')?.click(), 500)} className="btn btn-primary">{strings('button.close')}</button>
              ) : (
                <button type="button" data-dismiss='modal' className="btn btn-primary">{strings('button.close')}</button>
              )}
              </div>
            </div>
          </div>
        </div>
        <button 
          className='d-none'
          data-toggle="modal" 
          data-target="#modalReservation"
          id='open-modal-reservation'
        />
      </>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InfoReceiveCommunications)