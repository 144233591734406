
export default class Types {

  /** DEALERS **/
  static GET_DEALERS_FAILED = 'GET_DEALERS_FAILED';
  static GET_DEALERS_SUCCESS = 'GET_DEALERS_SUCCESS';

  /** GLOBAL CONFIG **/
  static GET_CONFIG_FAILED = 'GET_CONFIG_FAILED';
  static GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';

  /** UPDATE STATE **/
  static SET_APP_CONFIG_STATE = 'SET_APP_CONFIG_STATE';
  static SET_LOADING_APP_CONFIG = 'SET_LOADING_APP_CONFIG';
}
