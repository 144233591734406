import Types from './Types';

const INITIAL_STATE = {
  dataModels: [],
  isLoadingModels: false
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {

    /** GET MODELS **/
    case Types.GET_MODELS_HOME_SUCCESS:
      return {...state,
        dataModels: action.payload.items,
      };

    /** UPDATE STATE **/
    case Types.SET_MODELS_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
