import {strings} from '../../resources/locales/i18n';
import VehiclesManager from '../vehicles/VehiclesManager';

export const typeFilter = {
  POWER: 'POWER',
  PRICE: 'PRICE',
  COlOR: 'COlOR'
}

export const comparatorVehicleSelect =[
  {id: typeFilter.POWER, value: strings("vehicleDetails.power")},
  {id: typeFilter.PRICE, value: strings("comparator.price")},
  {id: typeFilter.COlOR, value: strings("comparator.color")}
]

export const comparatorVehiclePower = {
  id: '',
  title: strings("placeholder.name"),
  titleMethod: (item) => VehiclesManager.getFamilyDescription(item),
  typeFilter: typeFilter.POWER,
  item:[
    {
      title: strings('vehicleDetails.power'),
      method: (item) => {
        return strings('vehicleDetails.powerValue', {value: VehiclesManager.getPower(item)});
      }
    },
    {
      title: strings('vehicleDetails.consumption'),
      method: (item) => {
        return VehiclesManager.getConsumptionFormatted(item);
      }
    }
  ]
}

export const comparatorPrice = {
  id: '',
  title: strings("comparator.price"),
  titleMethod: (item) => VehiclesManager.getFinalPriceFormat(item),
  typeFilter: typeFilter.PRICE,
  item:[]
}

export const comparatorColor = {
  id: '',
  title: strings("comparator.color"),
  titleMethod: (item) => VehiclesManager.getColorDescription(item),
  typeFilter: typeFilter.COlOR,
  item:[]
}







