import VehiclesManager from '../vehicles/VehiclesManager';
import Types from './Types';
import {ToastConstructor} from '../toasts/ToastsManager';
import {getAsset} from '../../resources/assets/assets';
import {strings} from '../../resources/locales/i18n';
import {addToast} from '../toasts/ToastsActions';


export const addComparator = (vehicle) => async(dispatch, getState) => {
  const {vehiclesCompareMax} = getState().AppReducer;
  const {dataComparator} = getState().ComparatorReducer;
  const idVehicle = VehiclesManager.getProductId(vehicle);
  let isAdded = false;

  if (dataComparator.length === parseInt(vehiclesCompareMax)){
    return;
  }

  dataComparator.map((item) => {
    if (idVehicle === VehiclesManager.getProductId(item)) {
      isAdded = true
    }
  })

  if (!isAdded) {
    dispatch(addToast(ToastConstructor({
        icon: getAsset('icCheck'),
        id: VehiclesManager.getProductId(vehicle),
        item: vehicle,
        title: strings('toasts.vehicle.addVehicle'),
        delay: 5000,
        body: strings('toasts.vehicle.nameVehicleAdd', {vehicle: VehiclesManager.getName(vehicle), price: VehiclesManager.getFinalPriceFormat(vehicle)})
      })
    ))

    return dispatch({
      type: Types.ADD_VEHICLE_COMPARATOR,
      payload: vehicle
    });
  }

  dispatch(addToast(ToastConstructor({
      icon: getAsset('icError'),
      id: VehiclesManager.getProductId(vehicle),
      item: vehicle,
      title: strings('toasts.vehicle.notAddVehicleTitle'),
      delay: 5000,
      body: strings('toasts.vehicle.notAddVehicle', {vehicle: VehiclesManager.getName(vehicle), price: VehiclesManager.getFinalPriceFormat(vehicle)})
    })
  ))
};

export const setComparatorState = ({ prop, value }) => ({
  type: Types.SET_COMPARATOR_STATE,
  payload: { prop, value }
});

export const removeComparator = (vehicle) => async(dispatch, getState) => {
  const {dataComparator} = getState().ComparatorReducer;
  const idVehicle = VehiclesManager.getProductId(vehicle)

  let isAdded = false;
  let index = -1;

  dataComparator.map((item, indexItem) => {
    if (idVehicle === VehiclesManager.getProductId(item)) {
      isAdded = true
      index = indexItem
    }
  })

  if (isAdded && index !== -1) {
    dispatch({
      type: Types.REMOVE_VEHICLE_COMPARATOR,
      payload: index
    });
  }

};


