import React, { Component } from 'react'

export class SelectColors extends Component {
  render() {
    return (
      <div className="pt-3 pb-1">
        <div className="d-flex flex-wrap">
          {this.renderColors()}
        </div>
      </div>
    )
  }

  renderColors() {
    const { colors, onChange, colorsRedux } = this.props;

    if (colors && colors.length > 0) {
      return colors.map((item, index) => {
        if (item.color) {
          return (
            <div key={index} className="form-check form-option text-center mb-2 mx-1">
              <input className="form-check-input" type="checkbox" disabled />
              <label
                className="form-option-label rounded-circle"
                style={
                  colorsRedux && colorsRedux.includes(item.id) ?
                    { border: '2px solid var(--secondary)' } :
                    {}
                }>
                <span
                  className="form-option-color rounded-circle"
                  style={item.color ? { backgroundColor: item.color } : { backgroundColor: '' }}
                  onClick={(e) => onChange(e)}
                  id={item.id}
                />
              </label>
            </div>
          )
        }
      })
    }
  }
}

export default SelectColors;
