
export const emailValidator = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
};

export const isChecked = (checkBoxValue) => {
  // Check format
  if (!checkBoxValue || typeof checkBoxValue !== 'boolean') return false;

  return true;
};

export const isValidCardCVV = (cvv) => {
  // Check format
  if (!cvv || typeof cvv !== 'string') return false;

  // Check length
  return cvv.length >= 3;
};

export const isValidCardExpirationDate = (expirationDate) => {
  // Check format
  if (!expirationDate || typeof expirationDate !== 'string') return false;

  // Check length
  return expirationDate.length >= 5;
};

export const isValidCardNumber = (card, cardLength) => {
  // Check format
  if (!card || typeof card !== 'string') return false;

  // Check length
  return card.length >= cardLength;
};

export const isValidDni = (dni) => {
  // Check format
  if (!dni || typeof dni !== 'string') return false;

  let number
  let leter
  let word
  let regular_dni_expression = /^\d{8}[a-zA-Z]$/;

  if(regular_dni_expression.test (dni)){
    number = dni.substr(0,dni.length-1);
    leter = dni.substr(dni.length-1,1);
    number = number % 23;
    word='TRWAGMYFPDXBNJZSQVHLCKET';
    word=word.substring(number,number+1);
    if (word!=leter.toUpperCase()) {
      //console.log('Dni erroneo, la word del NIF no se corresponde');
      return false
    }else{
      //console.log('Dni correcto');
      return true
    }
  }else{
    //console.log('Dni erroneo, formato no válido');
    return false
  }
}

export const isValidEmail = (email) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // Check format
  if (!email || typeof email !== 'string') return false;

  return regex.test(email);
};

export const isValidLoginForm = (username, password) => {
  return isValidEmail(username) && isValidPassword(password);
};

export const isValidName = (name) => {
  // Check format
  if (!name || typeof name !== 'string') return false;

  // Check length
  return name.length > 0;
};

export const isValidNumber = (value) => {
  // Check format
  if (!value || typeof value !== 'number') return false;

  // Check length
  return value.length > 0;
};

export const isValidObject = (value) => {
  // Check format
  if (!value || typeof value !== 'object') return false;

  // Check length
  return true;
};

export const isValidPassword = (password) => {
  // Check format
  if (!password || typeof password !== 'string') return false;

  // Check length
  return password.length >= 6;
};

export const isValidPasswordCoincidence = (password, passwordRepeat) => {
  // Check format
  if (!password || !passwordRepeat || typeof password !== 'string' ||  typeof passwordRepeat !== 'string') return false;

  // Check length
  if (password.length !== passwordRepeat.length) return false;

  // Check passwords
  return password.trim() === passwordRepeat.trim();
};

export const isValidPhone = (phone) => {
  // Check format
  if (!phone || typeof phone !== 'string') return false;

  if (!/^(\d{3}\s{0,1}){3}$/g.test(phone) && !/^\d{3}(\s\d{2}){3}$/g.test(phone)) return false;

  // Check length
  return phone.length >= 9;
};

export const isValidPostalCode = (postalCode) => {
  // Check format
  if (!postalCode || typeof postalCode !== 'string') return false;

  // Check length
  return postalCode.length >= 5;
};

export const isValidSelect = (value) => {
  // Check value
  return value !== '';
};

export const isValidString = (value) => {
  // Check format
  if (!value || typeof value !== 'string') return false;

  // Check length
  return value.length > 0;
};

export const isValidStringWithoutNumber = (value) => {
  // Check format
  if (!value || typeof value !== 'string') return false;
  
  if (/\d/g.test(value)) return false;

  // Check length
  return value.length > 0;
};

export const isValidTextArea = (value, minLength) => {
  // Check format
  if (!value || typeof value !== 'string') return false;

  // Check length
  return value.length > minLength;
};


