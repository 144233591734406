import React, { Component } from 'react'
import { connect } from 'react-redux'

//resources
import { strings } from '../../../../../common/resources/locales/i18n'

class InfoStatisticalAnalysis extends Component {
  render() {
    const { openModalReservation } = this.props
    return (
     <>
      <div className="modal fade" id="modalInfoAnalisisEstadisticos" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">

          <div className="modal-header d-flex align-items-center bg-light">
            <div className="h5 modal-title m-0">
              ANÁLISIS ESTADÍSTICOS Y EVALUACIÓN DE PERFILES
            </div>
            {openModalReservation ? (
              <button type="button" className="close p-3" onClick={() => setTimeout(() => document.getElementById('open-modal-reservation')?.click(), 500)} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            ) : (
              <button type="button" className="close p-3" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>

          <div className="modal-body">
            <p>Aceptas que tus datos de carácter personal puedan utilizarse por DOMINGO ALONSO
                GROUP, S.L., con el fin de elaborar un perfil individualizado del cliente, mediante
                procedimientos estadísticos. Como resultado de dicho estudio estadístico, nos permite la
                realización de análisis de perfiles y se te podrán remitir comunicaciones de Marketing
                más personalizadas, en función de tus preferencias. Podrán incorporarse a los perfiles
                que se elaboren acerca del cliente, los siguientes datos que se detallan a continuación, los
                que hayas facilitado personalmente o los que se puedan haberse generado durante la
                utilización de los productos y servicios de DOMINGO ALONSO GROUP, S.L., de
                concesionarios, agentes o talleres autorizados: datos de contacto (como el nombre, la
                dirección, DNI, correo electrónico, matrícula, teléfono móvil); información adicional
                (como el concesionario preferido, el más utilizado o las aficiones); datos de identificación
                de usuario (como el número de cliente o el número de contrato); historial del cliente
                (como la recepción de ofertas, los datos de compra del vehículo o los datos del
                concesionario); datos del vehículo (kilometraje, autonomía de crucero); datos de apps,
                sorteos, concursos, webs, landing page o redes sociales. En el Anexo 4 se muestra una
                lista completa de las Categorías de Datos. Criterios de conservación de los datos: se
                conservarán mientras exista un interés mutuo para mantener el fin del tratamiento y
                cuando ya no sea necesario para tal fin, se suprimirán con medidas de seguridad
                adecuadas para garantizar la seudonimización de los datos o la destrucción total de los
                mismos. Comunicación de los datos: no se comunicarán los datos a terceros, salvo
                obligación legal. En este caso informar que <strong>DOMINGO ALONSO GROUP, S.L.</strong>,
                pertenece a un Grupo Empresarial y existe un interés legítimo en transmitir datos
                personales dentro del mismo para fines administrativos internos, incluido el tratamiento
                de datos personales de clientes. Por lo que cualquier concesionario que haya sido
                identificado como preferido o haya intervenido en el proceso de compra, podrá tener tus
                datos y comunicarse contigo En las distintas webs del grupo, podrás acceder a la
                información acerca del mismo. Y, si deseas que alguna empresa reflejada dentro del
                Grupo Empresarial no acceda a los datos, nos lo puedes comunicar.</p>
            <p>Todos los consentimientos son orientados a ésta entidad, DOMINGO ALONSO GROUP,
                S.L. Te recordamos que, en todas las comunicaciones comerciales, realizadas por
                DOMINGO ALONSO GROUP, S.L., siempre te damos la opción de darte de baja. Te
                recordamos que tu consentimiento, tiene carácter de revocable. Puedes revocarlo cuando
                lo desees, con efecto inmediato, contactando con el servicio de Atención al Cliente, al
                correo protecciondatos@domingoalonsogroup.com, y al teléfono 828 701 208</p>
          </div>

          <div className="modal-footer">
            {openModalReservation ? (
              <button type="button" data-dismiss='modal' onClick={() => setTimeout(() => document.getElementById('open-modal-reservation')?.click(), 500)} className="btn btn-primary">{strings('button.close')}</button>
            ) : (
              <button type="button" data-dismiss='modal' className="btn btn-primary">{strings('button.close')}</button>
            )}
          </div>
        </div>
      </div>
      </div>
      <button 
        className='d-none'
        data-toggle="modal" 
        data-target="#modalReservation"
        id='open-modal-reservation'
      />
     </>
    )
  }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(InfoStatisticalAnalysis)
