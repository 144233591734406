import React, { Component } from 'react';
import {formatToPrice} from '../../../common/utils/Utils';

class RangeInput extends Component {


  render() {
    const { id, onChange, value, label, maxPrice, minPrice, inputClean } = this.props;
    //inputClean: return without className by default

    if(inputClean && inputClean.value) {
      return(
        <>
        <label className={inputClean.classNameLabel} htmlFor={id}>
          {inputClean.containLabel}
        </label>
        <input 
          type="range" 
          className={inputClean.classNameInput} 
          id={id} 
          onChange={(e) => onChange(e)}
          value={value}
          min={inputClean.minValue}
          max={inputClean.maxValue}
          step={inputClean.step}
        />
        </>
      )
    }

    return (
      <div>
        <h6 className="mb-0 text-dark">{label}</h6>
        <div>
          <label className="d-flex small mb-0" htmlFor="customRange1">{formatToPrice(minPrice)}
            <span className="ml-auto font-weight-bold">{formatToPrice(value)}</span>
            <span className="ml-auto font-weight">{formatToPrice(maxPrice)}</span>
          </label>
          <input
            type="range"
            className="custom-range"
            id={id}
            onChange={(e) => {
              onChange(e.target.id, e.target.value)}}
            value={value|| ''}
            min={minPrice}
            max={maxPrice}
            step={Number.isInteger(minPrice) && Number.isInteger(maxPrice) ? "1" : "0.01"}
          />
        </div>
      </div>
    );
  }



}

export default RangeInput;
