import Types from './Types';

const INITIAL_STATE = {
  dataComparator: [],
  filterComparator: []
};

export default (state = INITIAL_STATE, action) => {
  let copyDataComparator;
  switch (action.type) {

    case Types.ADD_VEHICLE_COMPARATOR:
      copyDataComparator = state.dataComparator.map((item) => {return item});
      copyDataComparator.push(action.payload);
      return{...state, dataComparator: copyDataComparator};


    case Types.REMOVE_VEHICLE_COMPARATOR:

      state.dataComparator.splice(action.payload, 1)

      return{...state, dataComparator: state.dataComparator};

    /** UPDATE STATE **/
    case Types.SET_COMPARATOR_STATE:
      return { ...state, [action.payload.prop]: action.payload.value };

    default:
      return state;
  }
}
