
export default class Routing {

  /** App **/
  static id = ':id';
  static idOptional = '/:id?';
  static nowhere = '#';
  static home = '/';

  /** Auth Scene **/
  static authScene               = '/authScene';
  static login                   = "/login";
  static register                = '/register';
  static forgotPassword          = '/forgot-password';
  static resetPassword           = '/reset-password';
  static rememberPassword        = '/rememberPassword';
  static rememberPasswordMessage = '/rememberPasswordMessage';

  /** Contact **/
  static contact = '/contacto'
  static products = '/productos/';

  /** Comparator **/
  static comparator = '/comparador';

  /** Vehicle **/
  static vehicle = '/vehiculos/';
  static vehiclesAllStock = '/vehiculos';
  static vehiclePromotion = '/vehiculos-promocion';
  static vehicleSearch = '/vehiculos';
  static vehicleDetail = '/vehiculo/';


  /** Page 404 **/
  static page404 = '/page-404';

  /** Maintenance page **/
  static maintenancePage = '/mantenimiento';

  /** Message confirmation **/
  static msgConfirmation = '/mensaje-confirmacion'

}
