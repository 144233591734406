export const filterTypeVehicles = {
  COLORS:         'colors',
  FUELS:          'fuels',
  SPECIAL_PRICE:  'special_price',
  PRICE:          'price',
  TRANSMISSIONS:  'transmissions',
  CUOTA :         'cuota'

}

export default class VehiclesFilterManager {

  static getCode = (data) => {
    return data && data.code ?
      data.code : '';
  };

  static getValueCode = (data) => {
    return data && data.value_code ?
      data.value_code : '';
  };

  static getFilterValueByCode = (data, code, value) => {

    if (data && data.length !== 0){
      let valueResponse = null
      data.map((item) => {
        if (item && VehiclesFilterManager.getCode(item) === code) {
          if (item.values && item.values.length !== 0)
          item.values.map((element) => {
            if (VehiclesFilterManager.getValueId(element) === value){
              valueResponse = value
            }
          })
        }
      })
      return valueResponse
    }
    return null
  };

  static getFilterValue = (item, colors, fuels, transmissions, specialPrice) => {

    const code = VehiclesFilterManager.getCode(item);

    switch (code) {
      case filterTypeVehicles.COLORS:
        return colors
      case filterTypeVehicles.FUELS:
        return fuels
      case filterTypeVehicles.TRANSMISSIONS:
        return transmissions
      case filterTypeVehicles.SPECIAL_PRICE:
        return specialPrice
    }
  };

  static getLabel = (data) => {
    return data && data.label ?
      data.label : '';
  };

  static getNumericValues = (data) => {
    if (data && data.values){
      return data.values.map((item) => {
        if (data.code === filterTypeVehicles.COLORS) {
          return {
            id: VehiclesFilterManager.getValueId(item),
            value: VehiclesFilterManager.getValueLabel(item),
            color: VehiclesFilterManager.getValueCode(item)
          }
        } else {
          return {
            id: VehiclesFilterManager.getValueId(item),
            value: VehiclesFilterManager.getValueLabel(item)
          }
        }
        
      })
    }
    return []
  };

  static getValueId = (data) => {
    return data && data.value_id ?
      data.value_id : '';
  };

  static getValueLabel = (data) => {
    return data && data.value_label ?
      data.value_label : '';
  };

  static hasHexColor = (numericValues) => {
    if (numericValues && numericValues.length > 0) {
      const have = numericValues.filter(item => item.color && item.color !== '');
      return have.length > 0;
    }
  } 
}
