
export default class Tags {

  /** DEL **/
  static DEL_CART_MINE_ITEMS      = 'DEL_CART_MINE_ITEMS';

  /** GET **/
  static GET_APP_CONFIG        = 'GET_APP_CONFIG';
  static GET_MODELS_HOME       = 'GET_MODELS_HOME';
  static GET_VEHICLE           = 'GET_VEHICLE';
  static GET_VEHICLES          = 'GET_VEHICLES';
  static GET_VEHICLES_SEARCH   = 'GET_VEHICLES_SEARCH';
  static GET_DEALERS           = 'GET_DEALERS';
  static GET_URL               = 'GET_URL';

  /** POST **/
  static POST_CONTACT                = 'POST_CONTACT';
  static POST_FINANCING_SIMULATOR    = 'POST_FINANCING_SIMULATOR';

  /** PUT **/
  static PUT_CART_COLLECT_TOTALS     = 'PUT_CART_COLLECT_TOTALS';
}
