import React from 'react';
import VehiclesFilterManager, { filterTypeVehicles } from '../../../../../common/modules/vehicles/VehiclesFilterManager';
import SelectInput from '../../../components/SelectInput';
import {strings} from '../../../../../common/resources/locales/i18n';
import RangeInput from '../../../components/RangeInput';
import {parseStringVar} from '../../../../../common/utils/Utils';
import SelectColors from '../../../components/SelectColors';

const VehiclesFilter = (props) => {
  const {
    availableFilters, onChange, colors, fuels, transmissions, maxPrice, minPrice,
    price, specialPrice, maxCuota, minCuota, cuota
  } = props

  if (!availableFilters || availableFilters && availableFilters.length ===0){
    return <div/>
  }

  const filter = availableFilters.map((item, index) => {

    if (item && item.type === 'select'){
      return (
        <VehiclesFilterSelect
          data={item}
          key={index}
          onChange={onChange}
          value={VehiclesFilterManager.getFilterValue(item, colors, fuels, transmissions, specialPrice)}
          colorsRedux={colors}
        />
      )
    }

    if (item && item.type === 'checkbox'){
      return (
        <VehiclesFilterCheckbox
          data={item}
          value={VehiclesFilterManager.getFilterValue(item, colors, fuels, transmissions, specialPrice)}
          key={index}
          onChange={onChange}
        />
      )
    }
  })


  return (
    <form id="sidebar-form" className="mb-4 mb-lg-0">
      <h6 className="mb-3 text-muted text-uppercase d-none">Filtrar por</h6>
      <div id="filters-accordion" className="accordion">
        <VehiclesFilterLiner
          label={strings('vehicles.cuotePrice')}
          maxPrice={maxCuota}
          minPrice={minCuota}
          value={cuota}
          onChange={onChange}
          id='cuota'
        />
        <VehiclesFilterLiner
          label={strings('vehicles.maximumPrice')}
          maxPrice={maxPrice}
          minPrice={minPrice}
          value={price}
          onChange={onChange}
          id='price'
        />
        {filter}
      </div>
    </form>
  )
}

const VehiclesFilterLiner = ({label, id, maxPrice, minPrice, value, onChange}) => {

  return(
    <div className="item-accordion mb-2">
      <div className="btn-header-accordion border-bottom" id="filterhead5">
        <a href="#" className="collapse" data-toggle="collapse" data-target={'#' + id} aria-expanded={true}
           aria-controls="filter5">
          {label}
          <ion-icon name="chevron-up-outline"/>
        </a>
      </div>
      <div id={id} className="collapse show" aria-labelledby="filterhead5">
        <div className="pt-3 pb-1">
          <div className="form-group mb-0">
            <RangeInput
              type="range"
              className="custom-range"
              id={id}
              onChange={onChange}
              value={value}
              minPrice={minPrice}
              maxPrice={maxPrice}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const VehiclesFilterCheckbox = ({data, onChange, value}) => {
  const code = VehiclesFilterManager.getCode(data);
  const label = VehiclesFilterManager.getLabel(data);

  return (
    <div className="item-accordion mb-2">
      <div className="btn-header-accordion border-bottom" id="filterhead5">
        <a href="#" className="collapse" data-toggle="collapse" data-target={'#' + code} aria-expanded={true}
           aria-controls="filter5">
          {strings('vehicles.offers')}
          <ion-icon name="chevron-up-outline"/>
        </a>
      </div>

      <div id={code} className="collapse show" aria-labelledby="filterhead5">
      <div className="custom-control custom-checkbox mb-1">
        <div className='pt-3 pb-1'>
          <input
            id="customCheck2"
            type="checkbox"
            className="custom-control-input"
            code={code}
            onChange={(e) => {onChange(parseStringVar(code), e.target.checked)}}
            checked={value}
          />
            <label className="custom-control-label" htmlFor="customCheck2">{label}</label>
        </div>
      </div>
    </div>
    </div>
  )
}

const VehiclesFilterSelect = ({data, onChange, value, colorsRedux}) => {
  const code = VehiclesFilterManager.getCode(data);
  const label = VehiclesFilterManager.getLabel(data);
  const numericValues = VehiclesFilterManager.getNumericValues(data);
  const hasHexColor = VehiclesFilterManager.hasHexColor(numericValues);

  return (
    <div className="item-accordion mb-2">
      <div className="btn-header-accordion border-bottom" id="filterhead5">
        <a href="#" className="collapse" data-toggle="collapse" data-target={'#' + code} aria-expanded={true}
           aria-controls="filter5">
          {label}
          <ion-icon name="chevron-up-outline"/>
        </a>
      </div>
      <div id={code} className="collapse show" aria-labelledby="filterhead5">
        {code === filterTypeVehicles.COLORS && hasHexColor ?
          <SelectColors 
            colors={numericValues} 
            onChange={(e) => onChange(code, e.target.id)} 
            colorsRedux={colorsRedux}
          /> :
          <SelectInput
            id={code}
            dataChildren={numericValues}
            customStyleSelect={'form-control selectpicker mb-2'}
            showSelect={false}
            onChange={(e) => onChange(e.target.id, Array.from(
              e.target.selectedOptions,
              (option) => option.value
            ))}
            value={value}
            getItemId={true}
            styleContainer={'pt-3 pb-1'}
            multiple={true}
          />
        }
      </div>
    </div>
  )
}


export default VehiclesFilter
