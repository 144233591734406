$(document).ready(function () {
	
	// MODAL AUTO OPEN
	// Eliminar este script en Production !!
	/* setTimeout(function() {
		$('#modalBanner').modal('show');
	}, 4000);  */
	
	// CLOSE NAVBAR COLLAPSE
	$(document).click(function (event) {
		var clickover = $(event.target);
		var _opened = $(".navbar-collapse").hasClass("show");
		if (_opened === true && !clickover.hasClass("navbar-toggler")) {
				$(".navbar-toggler").click();
		}
	});
	
	// SELECTS
	$("select").selectpicker();

	// TOOLTIPS
	$('[data-toggle="tooltip"]').tooltip();

	// SMOOTH SCROLL WITH OFFSET
	$("a.smooth").on("click", function () {
		// e.preventDefault();
		var target = this.hash,
			$target = $(target);
		$("html, body")
			.stop()
			.animate(
				{
					scrollTop: $target.offset().top - 70,
				},
				900,
				"swing",
				function () {
					window.location.hash = target;
				}
			);
	});

	// IMG AS SVG
	jQuery("img.svg").each(function () {
		var $img = jQuery(this);
		var imgID = $img.attr("id");
		var imgClass = $img.attr("class");
		var imgURL = $img.attr("src");
		jQuery.get(
			imgURL,
			function (data) {
				// Get the SVG tag, ignore the rest
				var $svg = jQuery(data).find("svg");
				// Add replaced image's ID to the new SVG
				if (typeof imgID !== "undefined") {
					$svg = $svg.attr("id", imgID);
				}
				// Add replaced image's classes to the new SVG
				if (typeof imgClass !== "undefined") {
					$svg = $svg.attr("class", imgClass + " replaced-svg");
				}
				// Remove any invalid XML tags as per http://validator.w3.org
				$svg = $svg.removeAttr("xmlns:a");
				// Replace image with new SVG
				$img.replaceWith($svg);
			},
			"xml"
		);
	});

	// OWL SLIDER
	$(".card-product .owl-carousel").owlCarousel({
		loop: false,
		//margin: 0,
		nav: false,
		dots: true,
		items: 1,
	});
	$("#product-info-slider").owlCarousel({
		loop: false,
		//margin: 0,
		nav: false,
		dots: true,
		items: 1,
	});
});
