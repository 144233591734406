//Actions
import { startToast } from '../../../web/src/resources/styles/js/custom';

//Resources
import Types from './Types';

export const addToast = (props) => async(dispatch, getState) => {
  const { delay } = props;
  let { dataToasts } = getState().ToastsReducer;

  dataToasts.push(props);
  dispatch(setComponentData({prop: 'dataToasts', value: dataToasts.slice()}));
  if(startToast) {
    if (delay){
      setTimeout(() => { dispatch(deleteElementById({...props, prop: 'dataToasts'}))}, delay);
    }
    await startToast();
  }
};

export const clearDataComponents = () => ({
  type: Types.CLEAR_COMPONENT_DATA,
});

export const deleteElementById = (props) => async(dispatch, getState) => {
  const { dataToasts } = getState().ToastsReducer;
  const { id, prop, clickRemoveToast } = props;
  
  let position = -1;
  dataToasts.map((item, index) => {
    if (item.id === id) {
      position = index;
    }
  });
  
  if (position !== -1) {
    dataToasts.splice(position, 1);
    dispatch(setComponentData({prop, value: !clickRemoveToast ? dataToasts.slice() : dataToasts}));
  }
  
};

export const setComponentData = ({ prop, value }) => ({
  type: Types.SET_COMPONENT_DATA,
  payload: { prop, value }
});
